import React, { Component } from "react";
import PropTypes from "prop-types";
import IconMore from "@cx/ui/Icons/IconMore";
import Dropdown from "@cx/ui/Dropdown";
import { openNewWindow } from "../../utils/new-window.util";
import csrService from "../../quote-summary/services/csr.service";
import {
  getCustomer,
  getVehicle
} from "../../past-quotes/utils/repair-order-details.util";
import pastQuotesService from "../../past-quotes/services/past-quotes.service";
import { toast } from "@cx/ui/Toast";
import LoadingIndicator from "@cx/ui/LoadingIndicator";

export default class CsrActionMenu extends Component {
  constructor(props) {
    super(props);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.state = this.createInitialState(props);
  }

  createInitialState(props) {
    const highlightAllOnFocus = true;
    const { data } = props;
    return {
      quoteRecord: data,
      menuItemsUp: false,
      highlightAllOnFocus
    };
  }

  componentDidMount() {
    const { data } = this.props;
    const isHistoricalRepairOrder = data.roId && data.repairOrder;
    if (isHistoricalRepairOrder) {
      this.setState({ loadingPastQuotes: true });
      this.getPastQuotesSource(
        this.props.data.dealerCode,
        this.props.data.customerId
      )
        .then(res => {
          const filteredQuote = res.filter(
            quote => quote.roNumber == this.props.data.roId
          );
          if (filteredQuote.length > 0) {
            this.setState({
              pastQuoteData: {
                confirmationId: filteredQuote[0].confirmationId,
                quoteId: filteredQuote[0].quoteId
              }
            });
          }
        })
        .finally(() => {
          this.setState({ loadingPastQuotes: false });
        });
    }
  }

  getPastQuotesSource = async (dealerCode, customerId) => {
    try {
      return pastQuotesService.getFinalizedPastQuotes(dealerCode, customerId);
    } catch (error) {
      const msg = !error ? "Error occurred while loading data" : error.message;
      toast.error(msg, {
        closeOnClick: true
      });
    }
  };

  shouldShowContentPanelUp = (parentId, childNode, panelHeight) => {
    const gridBoundingBox = document
      .querySelector(parentId)
      .getBoundingClientRect();
    const childBoundingBox = childNode.getBoundingClientRect();
    // NOTE: Other components use gridBoundingBox.bottom, but some of them still render partially offscreen.
    //       Using gridBoundingBox.height seems more reliable, at least for this particular component.
    return childBoundingBox.bottom + panelHeight > gridBoundingBox.height;
  };

  // This gets called automatically by the AG Grid.
  afterGuiAttached() {
    if (this.state.highlightAllOnFocus) {
      const menuItemsUp = this.shouldShowContentPanelUp(
        "#grid-wrapper",
        this.wrapperRef,
        60
      );
      this.setState({
        highlightAllOnFocus: false,
        menuItemsUp
      });
    }
  }

  setWrapperRef = node => {
    this.wrapperRef = node;
  };

  renderButtons = () => {
    const { parentHandle, data } = this.props;
    const { quoteRecord, menuItemsUp, pastQuoteData, loadingPastQuotes } =
      this.state;
    const { documentsActionHandler, editActionHandler } = parentHandle;
    // NOTE: If we need any of this next stuff, get context from parentHandle.
    // const { localeStrings, userPermissions } = context;
    const { quoteStatus } = quoteRecord;
    const isHistoricalRepairOrder = data.roId && data.repairOrder;
    const isPreRo = data.quoteStatus === "PRE_RO";
    const roNumberDisplay = isHistoricalRepairOrder
      ? data.roId
      : csrService.getRONumberDisplay(quoteRecord);
    const modalRoute = isHistoricalRepairOrder
      ? "/archivedeventlog"
      : "/servicelog";
    const menuOptions = [
      {
        label: "View repair order",
        value: "ro",
        onSelect: () => {
          this.props.api.stopEditing();
          editActionHandler(quoteRecord);
        }
      },
      {
        label: "View activity log",
        value: "log",
        onSelect: () => {
          this.props.api.stopEditing();
          openNewWindow(
            modalRoute,
            null,
            quoteRecord.confirmationId,
            roNumberDisplay,
            0,
            0,
            900,
            500,
            `RO ${roNumberDisplay} - Activity log`,
            isHistoricalRepairOrder
              ? JSON.stringify({
                  customer: getCustomer(data.repairOrder.customer),
                  vehicle: getVehicle(data.repairOrder.vehicle),
                  mileageIn: data.repairOrder.mileageIn?.mileage,
                  mileageOut: data.repairOrder.mileageOut?.mileage,
                  eventLogs: data.repairOrder.eventLogs ?? []
                })
              : null
          );
        }
      }
    ];

    if (!isHistoricalRepairOrder && !isPreRo) {
      menuOptions.push({
        label: "View documents",
        value: "ro",
        onSelect: async () => {
          this.props.api.stopEditing();
          documentsActionHandler(quoteRecord);
        }
      });
    } else if (!isPreRo) {
      if (loadingPastQuotes) {
        menuOptions.push({
          label: "View documents",
          value: "ro",
          component: (
            <LoadingIndicator
              htmlId="loadingIndicatorSmall"
              floatToSide="left"
            />
          )
        });
      } else if (pastQuoteData) {
        menuOptions.push({
          label: "View documents",
          value: "ro",
          onSelect: async () => {
            this.props.api.stopEditing();
            if (quoteRecord.confirmationId && quoteRecord.quoteId) {
              documentsActionHandler(quoteRecord);
            } else {
              documentsActionHandler({
                ...quoteRecord,
                ...pastQuoteData
              });
            }
          }
        });
      } else {
        menuOptions.push({
          label: "View documents",
          value: "ro",
          disabled: true
        });
      }
    }

    return (
      <Dropdown
        icon={<IconMore isActive={true} />}
        htmlId="csrActionBtn"
        name="csrActionBtn"
        className="xmm-dotted-dropdown btn--icon"
        buttonStyle="link"
        displayCaret={false}
        size="small"
        dropup={menuItemsUp}
        defaultOpen={true}
        disabled={quoteStatus === "EXPIRED"}
        options={menuOptions}
        pullRight
      />
    );
  };

  render() {
    const buttonList = this.renderButtons();
    return <div ref={this.setWrapperRef}>{buttonList}</div>;
  }
}

CsrActionMenu.propTypes = {
  data: PropTypes.object, // @note- this data is default prop passed in cellEditorParams
  api: PropTypes.object, // see https://www.ag-grid.com/javascript-data-grid/cell-editing-start-stop/#editing-api
  parentHandle: PropTypes.object
};

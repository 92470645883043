// EXTERNAL LIBRARIES
import React from "react";
import Button from "@cx/ui/Button";
import Dropdown from "@cx/ui/Dropdown";
import PropTypes from "prop-types";
// STYLES
import "./pre-ro-dropdown.scss";

// CONSTANTS
const CREATE_PRE_RO = "CREATE_PRE_RO";
const RETURN_ALL_LINES_TO_RO = "RETURN_ALL_LINES_TO_RO";

const PreRoDropdown = ({ totalServices, onCancelPreRo, onCreatePreRo }) => {
  const options = [
    {
      label: "Create Pre-RO",
      onSelect: onCreatePreRo,
      value: CREATE_PRE_RO
    },
    {
      label: "Return all lines to RO",
      onSelect: onCancelPreRo,
      value: RETURN_ALL_LINES_TO_RO
    }
  ];
  return (
    <div className="pre-ro-dropdown">
      <Button
        size="lg"
        htmlId="preRoButton"
        buttonStyle="secondary"
        className="pre-ro-btn"
        onClick={onCreatePreRo}
      >
        {`Pre-Ro (${totalServices})`}
      </Button>
      <Dropdown
        pullRight={false}
        dropup={true}
        size="lg"
        buttonStyle="link"
        htmlId="dropdownRoButton"
        className="btn-dropdown"
        options={options}
        name="dropdownRoButton"
      />
    </div>
  );
};

export default PreRoDropdown;

PreRoDropdown.propTypes = {
  // handlers
  onCreatePreRo: PropTypes.func,
  onCancelPreRo: PropTypes.func,
  // data
  totalServices: PropTypes.number
};

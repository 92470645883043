import React, { useCallback, useState, useEffect } from "react";
import isEmpty from "lodash/isEmpty";
// import ServiceList from "../../components/service-list/ServiceList";
import "./DeclinedServicesQuickFilterPage.scss";
import {
  useServiceSearchContext,
  Actions
} from "../../state/service-search.context";
import EditDeclinedServicePage from "./EditDeclinedServicePage";
import ServicesList from "../ServiceList/services-list.component";

const DeclinedServicesQuickFilterPage = props => {
  const { EditServiceModule } = props;
  const { state, dispatch, ctxGtmEvent, ctxRequestOperationDetails } =
    useServiceSearchContext();
  const { declinedServices, appConfig } = state;
  const [showEditCmp, setShowEditCmp] = useState(false);
  const [declinedAPIService, setDeclinedAPIService] = useState(null);
  const [saveFromExtApp, setSaveFromExtApp] = useState(false);

  const addSelectedDeclinedServiceFromExtApp = useCallback(
    async (declinedService, serviceComments, asrNotes, duration, opCode) => {
      if (declinedService && Object.keys(declinedService).length > 0) {
        const rawService = JSON.stringify(declinedService);
        const declinedServiceObj = {
          ...declinedService,
          serviceComments,
          asrNotes,
          durationMins: duration,
          dmsOpcode: opCode,
          quoteRawService: { rawService }
        };
        dispatch({
          type: Actions.SET_SELECTED_DECLINED_SERVICE,
          payload: declinedServiceObj
        });
      }
    },
    [dispatch]
  );

  const handleSaveDeclinedServiceFromExtApp = useCallback(
    (serviceComments, originalNotes, duration, opCode) => {
      addSelectedDeclinedServiceFromExtApp(
        declinedAPIService,
        serviceComments,
        originalNotes,
        duration,
        opCode
      );
    },
    [declinedAPIService, addSelectedDeclinedServiceFromExtApp]
  );

  useEffect(() => {
    // Saves the declined service when parent application is not QUOTING and
    // the user is not taken to EditDeclinedServicePage where saving would be normally done.
    if (!isEmpty(declinedAPIService) && saveFromExtApp) {
      const emptyComments = "";
      const originalNotes =
        declinedAPIService.notes && declinedAPIService.notes.length > 0
          ? declinedAPIService.notes
          : "";
      const duration =
        declinedAPIService.duration && declinedAPIService.duration > 0
          ? declinedAPIService.duration
          : 0;
      const opCode =
        declinedAPIService.opCode && declinedAPIService.opCode.length > 0
          ? declinedAPIService.opCode
          : "";
      handleSaveDeclinedServiceFromExtApp(
        emptyComments,
        originalNotes,
        duration,
        opCode
      );
    }
  }, [saveFromExtApp, declinedAPIService, handleSaveDeclinedServiceFromExtApp]);

  const handleServiceClick = useCallback(
    service => {
      ctxGtmEvent?.trackGAEventWithParam("ga.newquote.select_service_click", {
        result: service.operationSource,
        location: "declined-quick-filter"
      });
      ctxRequestOperationDetails(service);
      setDeclinedAPIService(service);
      if (appConfig.editModuleAccess) {
        setShowEditCmp(true);
        dispatch({
          type: Actions.SET_SEARCH_HEADER,
          payload: false
        });
      } else {
        setSaveFromExtApp(true);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, appConfig.editModuleAccess]
  );

  const updateParentState = closeEdit => {
    setShowEditCmp(closeEdit);
  };

  const editPage =
    showEditCmp && !isEmpty(declinedAPIService) ? (
      <EditDeclinedServicePage
        updateParentState={updateParentState}
        service={declinedAPIService}
        EditServiceModule={EditServiceModule}
      />
    ) : (
      ""
    );

  const parentCls =
    showEditCmp && !isEmpty(declinedAPIService) ? "hide-ele" : "empty-cls";

  return (
    <>
      {/* <div id="declinedServicesQuickFilterPageContainer" className={parentCls}>
        <ServiceList
          services={declinedServices}
          viewType="DECLINED"
          onSelectService={handleServiceClick}
        />
      </div> */}

      <ServicesList
        parentCls={parentCls}
        services={declinedServices}
        viewType="DECLINED"
        onSelectService={handleServiceClick}
      />
      {editPage}
    </>
  );
};

export default DeclinedServicesQuickFilterPage;

import React, { useEffect, useState, useContext } from "react";
import ModalDialog from "@cx/ui/ModalDialog";
import isEmpty from "lodash/isEmpty";
import Alert from "@cx/ui/Alert";
import Button from "@cx/ui/Button";
import LoadingIndicator from "@cx/ui/LoadingIndicator";

import isNull from "lodash/isNull";
import isArray from "lodash/isArray";
import cloneDeep from "lodash/cloneDeep";

import "./catalog-discount.scss";
import { Actions, useNewQuoteContext } from "../../../../state/NewQuoteContext";
import ErrorBoundary from "../error-boundary.component";
import globalOperationsService from "../../../page-wrapper/services/global-operations.service";
import DiscountRenderContentComponent from "./components/catalog-discount-content-renderer.component";
import {
  ADD_CATALOG_LINE_DISCOUNT,
  ADD_CATALOG_RO_LEVEL_DISCOUNT,
  CATALOG_DISCOUNT_ACTIONS,
  MODIFY_CATALOG_LINE_DISCOUNT,
  MODIFY_CATALOG_RO_LEVEL_DISCOUNT
} from "../../constants/adjustment.constant";
import csrService from "../../../quote-summary/services/csr.service";
import { AppContext } from "../../../../state/app-context";
import {
  DISCOUNT_TYPES,
  MAXIMUM_LIMIT_EXCEEDED
} from "./catalog-discount.constants";
import { addCatalogRoLevelFeesAndDiscounts } from "../catalog-fee/catalog-fee.service";
import {
  checkExpiredFeeOrDiscount,
  checkStartDateValidFeeOrDiscount
} from "../../../page-wrapper/utils/quote-util";
import {
  didROHasAnyROLevelDiscount,
  didROHasServicesWithDiscount,
  hasNonCombiningDiscount
} from "./components/catalog-discounts.utils";

const CatalogDiscount = props => {
  const appContext = useContext(AppContext);

  const { dispatch, state, blockUntilCompleted } = useNewQuoteContext();
  const {
    priceAdjustmentType,
    currentEditingService,
    vehicle,
    priceAdjustmentItem,
    quoteSummary,
    showPageMask
  } = state;
  const [discountPayload, setDiscountPayload] = useState(
    priceAdjustmentItem || null
  );

  const [showMask, setShowMask] = useState(false);
  const [availableDiscount, setAvailableDiscount] = useState([]);

  const [error, setError] = useState(null);

  const getDiscountData = async () => {
    const {
      extServiceId,
      operationSource,
      catalogOperationSource,
      operationId
    } = currentEditingService;
    const params = {
      operationId: operationId ?? extServiceId,
      operationSource: catalogOperationSource ?? operationSource
    };

    const res = await globalOperationsService.getGlobalOperationDetails(
      vehicle,
      params
    );

    const discounts = res?.discountsAndFees?.discounts
      ?.filter(c => c.allowDiscountRepair === 0)
      ?.filter(
        c => checkExpiredFeeOrDiscount(c) && checkStartDateValidFeeOrDiscount(c)
      );

    setAvailableDiscount(discounts || []);
    setShowMask(false);
  };
  const getRoLevelDiscountData = async () => {
    const {
      dealer: { dealerCode }
    } = appContext;
    const res = await csrService.getRoLevelCatalogDiscounts(dealerCode);
    const discounts = await res?.discounts
      .filter(c => c.allowDiscountRepair === 1)
      .filter(c => c.discountType === DISCOUNT_TYPES.PERCENT)
      .filter(
        c => checkExpiredFeeOrDiscount(c) && checkStartDateValidFeeOrDiscount(c)
      );
    setAvailableDiscount(discounts);
    setShowMask(false);
  };
  useEffect(() => {
    setShowMask(true);
    if (priceAdjustmentType.includes("CATALOG_RO_LEVEL_DISCOUNT") === true) {
      getRoLevelDiscountData();
    } else getDiscountData();
  }, []);

  useEffect(() => {
    // * Errors
    if (discountPayload?.discountType === DISCOUNT_TYPES.VARIABLE) {
      if (
        discountPayload?.dollarAmount !== null &&
        discountPayload?.dollarAmount > discountPayload?.discountMax
      ) {
        setError(MAXIMUM_LIMIT_EXCEEDED);
      } else {
        setError(null);
      }
    } else {
      setError(null);
    }
  }, [discountPayload?.discountType, discountPayload?.dollarAmount]);

  const checkIfDiscountCanBeAppliedNew = () => {
    let isDiscountAllowed = false;
    const isCurrentDiscountAllowingOthers =
      discountPayload?.applyWithOtherDiscount === 0;

    const isROHaveNotAllowedDiscount = hasNonCombiningDiscount(
      quoteSummary?.payers
    );

    const isServicesHaveNotAllowedDiscount = hasNonCombiningDiscount(
      quoteSummary?.quoteServices
    );

    const isROLevelDiscountAvailable = didROHasAnyROLevelDiscount();
    const isServiceLevelDiscountAvailable = didROHasServicesWithDiscount();

    if (priceAdjustmentType.includes("RO_LEVEL") === true) {
      //* For RO LEVEL Discounts logic

      const catalogDiscounts =
        discountPayload?.payerObject?.catalogDiscounts ?? [];

      if (
        (catalogDiscounts && catalogDiscounts?.length === 0) ||
        (priceAdjustmentItem !== null && catalogDiscounts?.length === 1)
      ) {
        if (isServiceLevelDiscountAvailable === true) {
          if (isServicesHaveNotAllowedDiscount === false) {
            if (isCurrentDiscountAllowingOthers === true) {
              isDiscountAllowed = false;
            } else isDiscountAllowed = true;
          } else isDiscountAllowed = false;
        } else if (isROLevelDiscountAvailable === true) {
          if (isROHaveNotAllowedDiscount === false) {
            if (isCurrentDiscountAllowingOthers === true) {
              isDiscountAllowed = false;
            } else isDiscountAllowed = true;
          } else isDiscountAllowed = false;
        } else isDiscountAllowed = true;
      } else {
        const alreadyAddedDiscountAllowed = catalogDiscounts?.some(c =>
          priceAdjustmentItem
            ? priceAdjustmentItem.dealerDiscountId != c.dealerDiscountId &&
              c.applyWithOtherDiscount === 0
            : c.applyWithOtherDiscount === 0
        );

        if (
          isCurrentDiscountAllowingOthers === false &&
          isServicesHaveNotAllowedDiscount === false &&
          isROHaveNotAllowedDiscount === false &&
          alreadyAddedDiscountAllowed === false
        ) {
          isDiscountAllowed = true;
        }
      }
    } else {
      //* LINE LEVEL Discounts Logic
      //* Fresh case no discount added
      if (
        (currentEditingService?.catalogDiscounts &&
          currentEditingService?.catalogDiscounts?.length === 0) ||
        (priceAdjustmentItem !== null &&
          currentEditingService?.catalogDiscounts?.length === 1)
      ) {
        if (isROLevelDiscountAvailable === true) {
          if (isROHaveNotAllowedDiscount === false) {
            if (isCurrentDiscountAllowingOthers === true) {
              isDiscountAllowed = false;
            } else isDiscountAllowed = true;
          } else isDiscountAllowed = false;
        } else isDiscountAllowed = true;
      } else {
        const alreadyAddedDiscountAllowed =
          currentEditingService?.catalogDiscounts?.some(c =>
            priceAdjustmentItem
              ? priceAdjustmentItem?.dealerDiscountId != c?.dealerDiscountId &&
                c.applyWithOtherDiscount === 0
              : c.applyWithOtherDiscount === 0
          );

        if (
          isCurrentDiscountAllowingOthers === false &&
          isROHaveNotAllowedDiscount === false &&
          alreadyAddedDiscountAllowed === false
        ) {
          isDiscountAllowed = true;
        }
      }
    }

    return isDiscountAllowed;
  };

  const onUpdatePayload = payload => {
    setDiscountPayload(payload);
  };

  const actionHandler = async action => {
    const tempDiscountPayload = {};
    tempDiscountPayload.op = action;
    tempDiscountPayload.value = discountPayload;
    if (priceAdjustmentType === ADD_CATALOG_LINE_DISCOUNT) {
      tempDiscountPayload.path = "/catalogDiscounts/-";
    }
    if (priceAdjustmentType === MODIFY_CATALOG_LINE_DISCOUNT) {
      const discountIndex = currentEditingService?.catalogDiscounts?.findIndex(
        discount =>
          discount.dealerDiscountId == priceAdjustmentItem?.dealerDiscountId
      );
      tempDiscountPayload.path = `/catalogDiscounts/${discountIndex}`;
      if (action === "remove") {
        delete tempDiscountPayload.value;
      }
    }
    const finalPayload = [tempDiscountPayload];
    await blockUntilCompleted(async () => {
      try {
        const response = await csrService.jsonPatchService(
          appContext,
          quoteSummary,
          currentEditingService,
          finalPayload
        );
        updateQuoteContext(response);
      } catch (e) {
        if (e.response.status === 422) {
          setError(e.response.data.message);
        }
      }
    });
  };

  const actionHandlerRoLevel = async action => {
    let tempDiscountPayload = {};
    let removeDiscountFromPayerPayload = null;

    if (priceAdjustmentType === ADD_CATALOG_RO_LEVEL_DISCOUNT) {
      const payerObjectForDiscount = cloneDeep(discountPayload?.payerObject);
      tempDiscountPayload = {
        catalogDiscounts: payerObjectForDiscount?.catalogDiscounts || []
      };
      discountPayload.payTypes = isArray(discountPayload?.payTypes)
        ? discountPayload?.payTypes
        : discountPayload?.payTypes?.split(",");
      delete discountPayload?.payerObject;
      tempDiscountPayload?.catalogDiscounts?.push(discountPayload);
      tempDiscountPayload.payerId = payerObjectForDiscount?.payerId;
    } else if (priceAdjustmentType === MODIFY_CATALOG_RO_LEVEL_DISCOUNT) {
      if (action === "remove") {
        const originalPayerObject = quoteSummary?.payers?.find(
          payer => payer?.payType === discountPayload?.feeDiscountPayerPayCode
        );
        const filteredDiscountPayload =
          originalPayerObject?.catalogDiscounts?.filter(
            item =>
              item.dealerDiscountId + "" !==
              priceAdjustmentItem?.dealerDiscountId + ""
          );
        tempDiscountPayload = {
          catalogDiscounts: filteredDiscountPayload || []
        };
        tempDiscountPayload.payerId = originalPayerObject?.payerId;
        delete discountPayload?.payerObject;
        delete discountPayload?.feeDiscountPayerPayCode;
      } else {
        const filteredDiscountPayload =
          discountPayload?.payerObject?.catalogDiscounts?.filter(
            item =>
              item.dealerDiscountId != priceAdjustmentItem?.dealerDiscountId
          );
        tempDiscountPayload = {
          catalogDiscounts: filteredDiscountPayload || []
        };
        discountPayload.payTypes = isArray(discountPayload?.payTypes)
          ? discountPayload?.payTypes
          : discountPayload?.payTypes?.split(",");
        tempDiscountPayload.payerId = discountPayload?.payerObject?.payerId;

        //* this payload is made in case we are updating payer for already added discount for modify flow i.e removing discount from previous payer
        if (
          priceAdjustmentItem?.feeDiscountPayerPayCode !==
          discountPayload?.feeDiscountPayerPayCode
        ) {
          const olderPayerObject = quoteSummary?.payers?.find(
            payer =>
              payer?.payType === priceAdjustmentItem?.feeDiscountPayerPayCode
          );
          const remainedDiscount = olderPayerObject?.catalogDiscounts?.filter(
            discount =>
              discount.dealerDiscountId + "" !==
              priceAdjustmentItem?.dealerDiscountId + ""
          );

          removeDiscountFromPayerPayload = {
            catalogDiscounts: remainedDiscount || []
          };
          removeDiscountFromPayerPayload.payerId = olderPayerObject?.payerId;
        }

        delete discountPayload?.payerObject;
        delete discountPayload?.feeDiscountPayerPayCode;
        tempDiscountPayload.catalogDiscounts.push(discountPayload);
      }
    }
    await blockUntilCompleted(async () => {
      let response = null;

      try {
        response = await addCatalogRoLevelFeesAndDiscounts(
          appContext,
          quoteSummary,
          tempDiscountPayload,
          tempDiscountPayload?.payerId
        );

        //* this request is made in case we are updating payer for already added discounts for modify flow
        if (removeDiscountFromPayerPayload !== null) {
          response = await addCatalogRoLevelFeesAndDiscounts(
            appContext,
            quoteSummary,
            removeDiscountFromPayerPayload,
            removeDiscountFromPayerPayload?.payerId
          );
        }
        updateQuoteContext(response);
      } catch (e) {
        console.log(e);
      }
    });
  };

  const updateQuoteContext = quoteObject => {
    if (!isEmpty(quoteObject)) {
      setDiscountPayload(null);
      dispatch({
        type: Actions.UPDATE_QUOTE,
        payload: quoteObject
      });
      dispatch({
        type: Actions.SET_PRICE_ADJUSTMENT_TYPE,
        payload: null
      });
      dispatch({
        type: Actions.SET_PRICE_ADJUSTMENT_ITEM,
        payload: null
      });
    }
  };

  const hideModalHandler = () => {
    setDiscountPayload(null);
    dispatch({
      type: Actions.SET_PRICE_ADJUSTMENT_TYPE,
      payload: null
    });
    dispatch({
      type: Actions.SET_PRICE_ADJUSTMENT_ITEM,
      payload: null
    });
  };

  const checkSaveButtonEnabled = () => {
    let btnDisabled = false;
    let isVariableEnabled = true; // ! disabled true

    const isDiscountAllowed = !checkIfDiscountCanBeAppliedNew();

    //* if isDiscountAllow == true then disabled = should be false
    //* if isDiscountAllow == false then disbaled should be true
    //* if variableEnabled false then disbaled  should be false
    //* if variableEnabled true then disbaled  should be true

    //* isDiscountAllow = true variableEnable = true then btnDisabled = false + false true
    //* isDiscountAllow = true variableEnable = false then btnDisbaled =false

    if (
      discountPayload?.discountType === DISCOUNT_TYPES.VARIABLE &&
      !isNaN(discountPayload?.dollarAmount) &&
      CATALOG_DISCOUNT_ACTIONS.includes(priceAdjustmentType) === true
    ) {
      if (
        !isNull(discountPayload?.dollarAmount) &&
        discountPayload?.dollarAmount <= discountPayload?.discountMax &&
        parseFloat(discountPayload?.dollarAmount) !== 0 &&
        parseFloat(discountPayload?.dollarAmount) !==
          parseFloat(priceAdjustmentItem?.dollarAmount) &&
        discountPayload?.dollarAmount !== ""
      ) {
        // ! means valid condition, btndisabled=false as user have updated the discount
        isVariableEnabled = false;
      }

      // * Fresh discount variable case
      if (isDiscountAllowed === true && isVariableEnabled === false) {
        btnDisabled = false;
      }
      if (isDiscountAllowed === true && isVariableEnabled === true) {
        btnDisabled = true;
      }
      //* case when changing the modifed discount
      if (isDiscountAllowed === false && isVariableEnabled === true) {
        btnDisabled = true;
      }
    } else {
      if (
        priceAdjustmentType === MODIFY_CATALOG_RO_LEVEL_DISCOUNT &&
        discountPayload?.feeDiscountPayerPayCode !==
          priceAdjustmentItem?.feeDiscountPayerPayCode
      ) {
        //* case when on updating the Payer Type on modify case
        btnDisabled = false;
      } else {
        //* old case line level
        if (
          discountPayload?.dealerDiscountId ===
          priceAdjustmentItem?.dealerDiscountId
        ) {
          btnDisabled = true;
        } else {
          btnDisabled = isDiscountAllowed;
        }
      }
    }

    return btnDisabled;
  };

  const ErrorMsg = () => {
    return (
      <Alert htmlId="dangerAlert" type="danger">
        {error || MAXIMUM_LIMIT_EXCEEDED}
      </Alert>
    );
  };

  const CdFooter = () => {
    return (
      discountPayload && (
        <div>
          {[
            MODIFY_CATALOG_LINE_DISCOUNT,
            MODIFY_CATALOG_RO_LEVEL_DISCOUNT
          ].includes(priceAdjustmentType) === true ? (
            <Button
              htmlId="catalogDiscountRemoveBtn"
              onClick={() =>
                priceAdjustmentType === MODIFY_CATALOG_RO_LEVEL_DISCOUNT
                  ? actionHandlerRoLevel("remove")
                  : actionHandler("remove")
              }
              className="float-left"
              buttonStyle={props.removeBtnStyle}
              tabIndex="-1"
            >
              Remove
            </Button>
          ) : null}
          <Button
            htmlId="CatalogDiscountCancelBtn"
            buttonStyle="secondary"
            onClick={hideModalHandler}
          >
            Cancel
          </Button>
          <Button
            htmlId="catalogDiscountSaveBtn"
            onClick={() =>
              [
                ADD_CATALOG_RO_LEVEL_DISCOUNT,
                MODIFY_CATALOG_RO_LEVEL_DISCOUNT
              ].includes(priceAdjustmentType)
                ? actionHandlerRoLevel(null)
                : actionHandler(
                    priceAdjustmentType === MODIFY_CATALOG_LINE_DISCOUNT
                      ? "replace"
                      : "add"
                  )
            }
            disabled={checkSaveButtonEnabled()}
            buttonStyle={props.okBtnStyle}
            tabIndex="0"
          >
            {[
              MODIFY_CATALOG_LINE_DISCOUNT,
              MODIFY_CATALOG_RO_LEVEL_DISCOUNT
            ].includes(priceAdjustmentType) === true
              ? props.updateText
              : props.okText}
          </Button>
        </div>
      )
    );
  };

  return (
    <>
      {!showPageMask ? (
        <ModalDialog
          htmlId="catalogDiscountsModal"
          backdrop="static"
          className="quarter-width catalog-discounts"
          show={priceAdjustmentType !== null}
          header={<h4 className="modal-title">Discount</h4>}
          footer={!showMask && error ? <ErrorMsg /> : <CdFooter />}
          onHide={hideModalHandler}
        >
          <div>
            <ErrorBoundary>
              {showMask ? (
                <LoadingIndicator htmlId="discountLoader" />
              ) : (
                availableDiscount && (
                  <DiscountRenderContentComponent
                    availableDiscountOptions={availableDiscount}
                    service={currentEditingService}
                    onUpdatePayload={onUpdatePayload}
                    selectedDiscountModify={priceAdjustmentItem}
                    priceAdjustmentType={priceAdjustmentType}
                    quoteSummary={quoteSummary}
                  />
                )
              )}
            </ErrorBoundary>
          </div>
        </ModalDialog>
      ) : null}
    </>
  );
};

export default CatalogDiscount;

CatalogDiscount.defaultProps = {
  message: "Test message",
  okBtnStyle: "primary",
  removeBtnStyle: "danger",
  okText: "Apply",
  updateText: "Update",
  removeText: "Remove",
  cancelText: "Cancel",
  hideRemove: false,
  hideOk: false,
  specialCase: false,
  removeActionText: "remove",
  replaceText: "replace",
  addText: "add"
};

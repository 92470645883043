/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState } from "react";
import PropTypes from "prop-types";

import Row from "@cx/ui/Row";
import Col from "@cx/ui/Col";
import Alert from "@cx/ui/Alert";
import DatePicker from "@cx/ui/DatePicker";
import TextInput from "@cx/ui/TextInput";
import SearchableSelect from "@cx/ui/SearchableSelect";
import SelectInput from "@cx/ui/SelectInput";
import TextArea from "@cx/ui/TextArea";
import Button from "@cx/ui/Button";

const CreateNewPoForm = props => {
  const {
    selectedParts,
    buyersList,
    supplierList,
    suppliersAddressList,
    showCreatePoError
  } = props;

  const [selectedSupplier, setSelectedSupplier] = useState("");
  const [selectedAddress, setSelectedAddress] = useState("");
  const [selectedBuyer, setSelectedBuyer] = useState("");
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedNote, setSelectedNote] = useState("");
  const [selectedNoteType, setSelectedNoteType] = useState("");

  const selectedPartsLabel = `${selectedParts.length} part(s) selected`;

  const onSuppliersInputChange = ev => {
    if (ev?.target?.value?.length > 0) {
      const selectedManufacturer = ev.target.value[0];
      setSelectedSupplier(selectedManufacturer);
      const selectedSupplierAddress = suppliersAddressList?.filter(
        address => address.id === selectedManufacturer.value
      );
      setSelectedAddress(selectedSupplierAddress[0].address);
    }
  };

  const isSaveDisabled =
    !selectedSupplier || !selectedAddress || !selectedBuyer || !selectedDate;

  const handleSubmit = e => {
    const formatDate = new Date(selectedDate);
    e.preventDefault();
    const newPurchaseOrder = {
      buyerId: selectedBuyer?.value,
      notes: [
        {
          note: selectedNote ? selectedNote : "sample",
          noteType: selectedNoteType
            ? selectedNoteType.toUpperCase()
            : "INTERNAL"
        }
      ],
      procurementType: "DROP",
      supplierId: selectedSupplier?.value,
      transactionDate: formatDate.toISOString().split("T")[0]
    };
    props.handleSaveNewPurchaseOrder(selectedParts, newPurchaseOrder);
  };

  return (
    <div>
      <Row>
        <Col xs={6} md={6}>
          <Alert htmlId="infoAlert" type="info" className="create-po-alert">
            Only one purchase order can be created per supplier.
          </Alert>
        </Col>
      </Row>
      {showCreatePoError ? (
        <Row>
          <Col xs={6} md={6}>
            <Alert htmlId="infoAlert" type="danger">
              Unable to create a purchase order at this time. Try again later.
            </Alert>
          </Col>
        </Row>
      ) : null}

      <h5>{selectedPartsLabel}</h5>
      <form id="createNewPoForm" autoComplete="off" onSubmit={handleSubmit}>
        <Row>
          <Col xs={9} md={9}>
            <Row>
              <Col xs={3} md={3}>
                <DatePicker
                  htmlId="transactionDateInput"
                  label="Transaction date"
                  className="vertical-date-picker"
                  name="transaction"
                  dateFormat="MM/dd/yyyy"
                  minDate={new Date() || ""}
                  selected={selectedDate}
                  onChange={ev => {
                    setSelectedDate(ev?.target?.value);
                  }}
                  required
                />
              </Col>
              <Col xs={3} md={3}>
                <SearchableSelect
                  displayPlaceholder={true}
                  placeholder="Select"
                  className="xmm-scrollable-select"
                  htmlId="supplierListSelect"
                  label="Supplier"
                  name="supplierListSelect"
                  enableMultiSelect={false}
                  maxHeight={150}
                  onChange={onSuppliersInputChange}
                  options={supplierList}
                  value={selectedSupplier?.value || ""}
                  required
                />
              </Col>
              <Col xs={3} md={3}>
                <TextInput
                  htmlId="supplierAddressInput"
                  label="Supplier address"
                  name="supplierAddress"
                  onChange={() => {}}
                  value={selectedAddress}
                  disabled
                />
              </Col>
              <Col xs={3} md={3}>
                <SearchableSelect
                  displayPlaceholder={true}
                  placeholder="Select"
                  className="xmm-scrollable-select"
                  htmlId="buyerListSelect"
                  label="Buyer"
                  name="buyerListSelect"
                  enableMultiSelect={false}
                  maxHeight={150}
                  onChange={ev => {
                    setSelectedBuyer(ev?.target?.value[0]);
                  }}
                  options={buyersList}
                  value={selectedBuyer?.value || ""}
                  required
                  disabled={!buyersList || buyersList.length === 0}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={3} md={3}>
                <SelectInput
                  htmlId="noteTypeSelector"
                  label="Note type"
                  name="noteType"
                  onChange={ev => {
                    setSelectedNoteType(ev?.target?.value);
                  }}
                  options={[
                    { value: "External", label: "External" },
                    { value: "Internal", label: "Internal" }
                  ]}
                />
              </Col>
              <Col xs={3} md={3}>
                <TextArea
                  htmlId="noteArea"
                  label="Notes"
                  name="notes"
                  maxLength={3000}
                  onChange={ev => {
                    setSelectedNote(ev?.target?.value);
                  }}
                  value={selectedNote}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Button
          htmlId="saveOrderAction"
          className="float-right"
          buttonStyle="primary"
          disabled={isSaveDisabled}
          type="submit"
          title={
            isSaveDisabled && !selectedAddress
              ? "Cannot create PO without supplier address"
              : ""
          }
        >
          Create PO
        </Button>
      </form>
    </div>
  );
};

export default CreateNewPoForm;

CreateNewPoForm.defaultProps = {
  selectedParts: [],
  buyersList: []
};

CreateNewPoForm.propTypes = {
  selectedParts: PropTypes.array,
  buyersList: PropTypes.array,
  supplierList: PropTypes.array,
  suppliersAddressList: PropTypes.array,
  handleSaveNewPurchaseOrder: PropTypes.func
};

import React, { useCallback, useContext, useEffect, useState } from "react";
import ModalDialog from "@cx/ui/ModalDialog";
import Button from "@cx/ui/Button";
import ErrorBoundary from "../error-boundary.component";
import { useNewQuoteContext, Actions } from "../../../../state/NewQuoteContext";
import {
  ADD_CATALOG_LINE_FEE,
  ADD_CATALOG_RO_FEE,
  FEES_TYPES,
  MODIFY_CATALOG_LINE_FEE,
  MODIFY_CATALOG_RO_FEE
} from "../../constants/adjustment.constant";
import globalOperationsService from "../../../page-wrapper/services/global-operations.service";
import FeeRenderContentComponent from "./component/fee-render-content.component";
import "./catalog-fee.scss";
import isEmpty from "lodash/isEmpty";
import {
  addCatalogRoLevelFeesAndDiscounts,
  catalogJSONPatchFunction,
  getCatalogROLevelFees
} from "./catalog-fee.service";
import { AppContext } from "../../../../state/app-context";
import Alert from "@cx/ui/Alert";
import LoadingIndicator from "@cx/ui/LoadingIndicator";
import isArray from "lodash/isArray";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";

const CatalogFee = props => {
  const appContext = useContext(AppContext);
  const { dispatch, state, blockUntilCompleted } = useNewQuoteContext();
  const {
    priceAdjustmentType,
    vehicle,
    currentEditingService,
    quoteSummary,
    showPageMask,
    priceAdjustmentItem
  } = state;
  const [availableFeeOptions, setAvailableFeeOptions] = useState(null);
  const [feePayload, setFeePayload] = useState(priceAdjustmentItem || null);
  const [mask, setMask] = useState(false);

  const handleOnGetOperationDetails = useCallback(async () => {
    const params = {
      operationSource:
        currentEditingService?.catalogOperationSource ??
        currentEditingService?.operationSource,
      operationId:
        currentEditingService?.operationId ??
        currentEditingService?.extServiceId
    };
    setMask(true);
    try {
      const globalOperationDetails =
        await globalOperationsService.getGlobalOperationDetails(
          vehicle,
          params
        );
      setMask(false);
      setAvailableFeeOptions(globalOperationDetails?.discountsAndFees?.fees);
    } catch (e) {
      setMask(false);
      setAvailableFeeOptions([]);
    }
  }, [
    currentEditingService?.extServiceId,
    currentEditingService?.operationSource,
    vehicle
  ]);

  const handleGetRoLevelFees = useCallback(async () => {
    setMask(true);
    try {
      const globalOperationDetails = await getCatalogROLevelFees(appContext);
      setMask(false);
      setAvailableFeeOptions(globalOperationDetails?.fees);
    } catch (e) {
      setMask(false);
      setAvailableFeeOptions([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onUpdatePayload = payload => {
    setFeePayload(payload);
  };

  const actionHandlerForROLevelFee = async action => {
    let tempFeePayload = {};
    let removeFeeFromPayerPayload = null;
    if (priceAdjustmentType === ADD_CATALOG_RO_FEE) {
      const payerObjectForFee = cloneDeep(feePayload?.payerObject);
      tempFeePayload = {
        catalogFees: payerObjectForFee?.catalogFees || []
      };
      feePayload.payTypes = isArray(feePayload?.payTypes)
        ? feePayload?.payTypes
        : feePayload?.payTypes?.split(",");
      delete feePayload?.payerObject;
      delete feePayload?.feeDiscountPayerPayCode;
      tempFeePayload?.catalogFees?.push(feePayload);
      tempFeePayload.payerId = payerObjectForFee?.payerId;
    } else if (priceAdjustmentType === MODIFY_CATALOG_RO_FEE) {
      if (action === "remove") {
        const originalPayerObject = quoteSummary?.payers?.find(
          payer => payer?.payType === feePayload?.feeDiscountPayerPayCode
        );
        const filteredFeePayload = originalPayerObject?.catalogFees?.filter(
          item =>
            item.dealerFeesId + "" !== priceAdjustmentItem?.dealerFeesId + ""
        );
        tempFeePayload = {
          catalogFees: filteredFeePayload || []
        };
        tempFeePayload.payerId = originalPayerObject?.payerId;
        delete feePayload?.payerObject;
        delete feePayload?.feeDiscountPayerPayCode;
      } else {
        const filteredFeePayload = feePayload?.payerObject?.catalogFees?.filter(
          item =>
            item.dealerFeesId + "" !== priceAdjustmentItem?.dealerFeesId + ""
        );
        tempFeePayload = {
          catalogFees: filteredFeePayload || []
        };
        feePayload.payTypes = isArray(feePayload?.payTypes)
          ? feePayload?.payTypes
          : feePayload?.payTypes?.split(",");
        tempFeePayload.payerId = feePayload?.payerObject?.payerId;

        //* this payload is made in case we are updating payer for already added fees for modify flow i.e removing fee from previous payer
        if (
          priceAdjustmentItem?.feeDiscountPayerPayCode !==
          feePayload?.feeDiscountPayerPayCode
        ) {
          const olderPayerObject = quoteSummary?.payers?.find(
            payer =>
              payer?.payType === priceAdjustmentItem?.feeDiscountPayerPayCode
          );
          const remainedFees = olderPayerObject?.catalogFees?.filter(
            fee =>
              fee.dealerFeesId + "" !== priceAdjustmentItem?.dealerFeesId + ""
          );

          removeFeeFromPayerPayload = { catalogFees: remainedFees || [] };
          removeFeeFromPayerPayload.payerId = olderPayerObject?.payerId;
        }

        delete feePayload?.payerObject;
        delete feePayload?.feeDiscountPayerPayCode;
        tempFeePayload.catalogFees.push(feePayload);
      }
    }

    await blockUntilCompleted(async () => {
      let response = null;
      try {
        response = await addCatalogRoLevelFeesAndDiscounts(
          appContext,
          quoteSummary,
          tempFeePayload,
          tempFeePayload?.payerId
        );
        //* this request is made in case we are updating payer for already added fees for modify flow
        if (removeFeeFromPayerPayload !== null) {
          response = await addCatalogRoLevelFeesAndDiscounts(
            appContext,
            quoteSummary,
            removeFeeFromPayerPayload,
            removeFeeFromPayerPayload?.payerId
          );
        }
        updateQuoteContext(response);
      } catch (e) {
        console.log(e, response);
        // if (response !== null) {
        //   updateQuoteContext(response);
        // }
      }
    });
  };

  const actionHandlerForLineLevelFee = async action => {
    const tempFeePayload = {};
    feePayload.feeMax =
      feePayload?.feesType === FEES_TYPES.DOLLAR ? null : feePayload?.feeMax;
    feePayload.payTypes = isArray(feePayload?.payTypes)
      ? feePayload?.payTypes
      : feePayload?.payTypes
      ? feePayload?.payTypes?.split(",")
      : [];
    tempFeePayload.op = action;
    tempFeePayload.value = feePayload;
    if (
      priceAdjustmentType === ADD_CATALOG_LINE_FEE ||
      priceAdjustmentType === ADD_CATALOG_RO_FEE
    ) {
      tempFeePayload.path = "/catalogFees/-";
    }
    if (
      priceAdjustmentType === MODIFY_CATALOG_LINE_FEE ||
      priceAdjustmentType === MODIFY_CATALOG_RO_FEE
    ) {
      const feeIndex = currentEditingService?.catalogFees?.findIndex(
        fee => fee.dealerFeesId === priceAdjustmentItem?.dealerFeesId + ""
      );
      tempFeePayload.path = `/catalogFees/${feeIndex}`;
      if (action === props.removeActionText) {
        delete tempFeePayload.value;
      }
    }
    const finalPayload = [tempFeePayload];
    await blockUntilCompleted(async () => {
      try {
        const response = await catalogJSONPatchFunction(
          appContext,
          quoteSummary,
          currentEditingService,
          finalPayload
        );
        updateQuoteContext(response);
      } catch (e) {
        console.log(e);
      }
    });
  };

  const hideModalHandler = () => {
    setFeePayload(null);
    dispatch({
      type: Actions.SET_PRICE_ADJUSTMENT_TYPE,
      payload: null
    });
    dispatch({
      type: Actions.SET_PRICE_ADJUSTMENT_ITEM,
      payload: null
    });
  };

  const checkSaveButtonEnabled = () => {
    let enabled = true;
    if (
      priceAdjustmentType === MODIFY_CATALOG_LINE_FEE ||
      priceAdjustmentType === MODIFY_CATALOG_RO_FEE
    ) {
      if (isEqual(priceAdjustmentItem, feePayload)) enabled = false;
    }

    if (
      feePayload?.feesType === FEES_TYPES.VARIABLE &&
      (feePayload?.dollarAmount === 0 || isNaN(feePayload?.dollarAmount))
    )
      enabled = false;
    return enabled;
  };

  const updateQuoteContext = quoteObject => {
    if (!isEmpty(quoteObject)) {
      dispatch({
        type: Actions.UPDATE_QUOTE,
        payload: quoteObject
      });
      dispatch({
        type: Actions.SET_PRICE_ADJUSTMENT_TYPE,
        payload: null
      });
      dispatch({
        type: Actions.SET_PRICE_ADJUSTMENT_ITEM,
        payload: null
      });
    }
  };

  const showModalFooter = () => {
    return (
      feePayload?.feesType === FEES_TYPES.DOLLAR ||
      parseFloat(feePayload?.appliedFee || 0) <= feePayload?.feeMax
    );
  };
  const modalFooter = (
    <div>
      {(priceAdjustmentType === MODIFY_CATALOG_LINE_FEE ||
        priceAdjustmentType === MODIFY_CATALOG_RO_FEE) &&
      priceAdjustmentItem?.feesType === feePayload?.feesType &&
      priceAdjustmentItem?.dealerFeesId === feePayload?.dealerFeesId + "" ? (
        <Button
          htmlId="removebtnModal"
          onClick={() =>
            priceAdjustmentType === MODIFY_CATALOG_RO_FEE
              ? actionHandlerForROLevelFee(props.removeActionText)
              : actionHandlerForLineLevelFee(props.removeActionText)
          }
          className="float-left"
          buttonStyle={props.removeBtnStyle}
          tabIndex="-1"
        >
          {props.removeText}
        </Button>
      ) : null}
      <Button
        htmlId="cancelbtnModal"
        buttonStyle="secondary"
        onClick={hideModalHandler}
      >
        {props.cancelText}
      </Button>

      <Button
        htmlId="okbtnModal"
        onClick={() =>
          priceAdjustmentType === ADD_CATALOG_RO_FEE ||
          priceAdjustmentType === MODIFY_CATALOG_RO_FEE
            ? actionHandlerForROLevelFee(null)
            : actionHandlerForLineLevelFee(
                priceAdjustmentType === MODIFY_CATALOG_LINE_FEE
                  ? props.replaceText
                  : props.addText
              )
        }
        disabled={!checkSaveButtonEnabled()}
        buttonStyle={props.okBtnStyle}
        tabIndex="0"
      >
        {priceAdjustmentType === MODIFY_CATALOG_LINE_FEE ||
        priceAdjustmentType === MODIFY_CATALOG_RO_FEE
          ? props.updateText
          : props.okText}
      </Button>
    </div>
  );

  useEffect(() => {
    if (
      priceAdjustmentType === ADD_CATALOG_LINE_FEE ||
      priceAdjustmentType === MODIFY_CATALOG_LINE_FEE
    ) {
      handleOnGetOperationDetails();
    }
    if (
      priceAdjustmentType === ADD_CATALOG_RO_FEE ||
      priceAdjustmentType === MODIFY_CATALOG_RO_FEE
    ) {
      handleGetRoLevelFees();
    }
  }, [handleGetRoLevelFees, handleOnGetOperationDetails, priceAdjustmentType]);

  return (
    <div>
      {!showPageMask ? (
        <ModalDialog
          htmlId="PriceAdjustmentModalDialog"
          backdrop="static"
          className="sample-modal-dialog quarter-width catalog-fee"
          show={priceAdjustmentType !== null}
          header={<h4 className="modal-title">Fees</h4>}
          footer={!mask && showModalFooter() ? modalFooter : ""}
          onHide={hideModalHandler}
        >
          <div className="sq-flex-container">
            <ErrorBoundary>
              {mask ? (
                <LoadingIndicator
                  htmlId="maskSpinner"
                  size="large"
                  color="gray"
                />
              ) : (
                <FeeRenderContentComponent
                  availableFeeOptions={availableFeeOptions}
                  service={currentEditingService}
                  onUpdatePayload={onUpdatePayload}
                  selectedFeeModify={priceAdjustmentItem}
                  priceAdjustmentType={priceAdjustmentType}
                  quoteSummary={quoteSummary}
                />
              )}
              {!showModalFooter() && feePayload ? (
                <Alert
                  htmlId="dangerAlert"
                  type="danger"
                  className="margin-top-12"
                >
                  Maximum limit exceeded
                </Alert>
              ) : null}
            </ErrorBoundary>
          </div>
        </ModalDialog>
      ) : null}
    </div>
  );
};

CatalogFee.defaultProps = {
  message: "Test message",
  okBtnStyle: "primary",
  removeBtnStyle: "danger",
  okText: "Apply",
  updateText: "Update",
  removeText: "Remove",
  cancelText: "Cancel",
  hideRemove: false,
  hideOk: false,
  specialCase: false,
  removeActionText: "remove",
  replaceText: "replace",
  addText: "add"
};

export default CatalogFee;

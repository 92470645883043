import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import ModalDialog from "@cx/ui/ModalDialog";
import Button from "@cx/ui/Button";
import Badge from "@cx/ui/Badge";
import NumericInput from "@cx/ui/NumericInput";
import SelectInput from "@cx/ui/SelectInput";
import PriceInput from "@cx/ui/PriceInput";
import format from "../../utils/format";
import {
  PAYER_TYPES,
  PAYMENT_METHODS,
  PAYMENT_TYPES,
  RO_STATUSES
} from "../../constants/csr.constants";
import appServices from "../../../../services/app.service";
import csrService from "../../../quote-summary/services/csr.service";
import "./finalize-ro-modal.scss";
import cloneDeep from "lodash/cloneDeep";
import moment from "moment";
import TextInput from "@cx/ui/TextInput";
import isEmpty from "lodash/isEmpty";
import { formatPrice } from "../../../../utils/formatter.util";
import Alert from "@cx/ui/Alert";
import LoadingIndicator from "@cx/ui/LoadingIndicator";
import isNull from "lodash/isNull";
import { appTypes } from "../../../../constants/app.constants";
import { AppContext } from "../../../../state/app-context";
import { checkChargeAccountValid } from "../../../page-wrapper/utils/quote-util";
import SplitPaymentComponent from "./split-payment.component";
import { YES } from "../../constants/adjustment.constant";
import { compare } from "fast-json-patch";
import {
  warrantyBadgeLabelsNew,
  warrantySubmissionStates
} from "../../../../constants/quote-status.constants";

const getDefaultPaymentsArray = paymentMethod => {
  return [
    {
      checkNumber: "",
      paymentDate: null,
      drawerNumber: "",
      paymentAmount: "",
      paymentMethod,
      paymentStatus: "closed"
    }
  ];
};

const FinalizeROModal = props => {
  const appContext = useContext(AppContext);
  const { dealerProperties } = appContext;
  const {
    show,
    quoteSummary,
    continueHandler,
    closeHandler,
    showFinalizeModal,
    printHandler,
    updatePaymentLines
  } = props;
  const [mileageOut, setMileageOut] = useState(quoteSummary.mileageOut);
  const legitPayers = csrService.getLegitPayers(quoteSummary, dealerProperties);
  const { quoteStatus } = quoteSummary;
  const [showMask, setShowMask] = useState(false);

  const [allowCharge, setAllowCharge] = useState(false);
  const [showAvailableBalance, setShowAvailableBalance] = useState(false);
  const [chargeAccountDetails, setChargeAccountDetails] = useState(null);

  const isSplitPaymentEnabled =
    dealerProperties.USE_SPLIT_PAYMENT_FEATURE === YES ? true : false;

  const isFinalized = quoteStatus === RO_STATUSES.FINALIZED.value;

  const isUseOEMWarrantyEnabled =
    dealerProperties?.ENGG_USE_OEM_WARRANTY === "Y" ? true : false;
  if (
    isNull(
      checkChargeAccountValid(appTypes.CSR, quoteSummary, dealerProperties)
    )
  ) {
    PAYMENT_METHODS.map(
      (c, i) => c.value === PAYMENT_TYPES.CHARGE && PAYMENT_METHODS.splice(i, 1)
    );
  }
  // Define hard-coded payer configs.
  const initialPayerConfigs = [
    {
      payType: PAYER_TYPES.C,
      order: 1,
      checkboxLabel: "Collect customer payment balance",
      closedPaytypeText: "Customer pay"
    },
    {
      payType: PAYER_TYPES.W,
      order: 2,
      checkboxLabel: "Close warranty",
      closedPaytypeText: "Warranty"
    },
    {
      payType: PAYER_TYPES.I,
      order: 3,
      checkboxLabel: "Close internal",
      closedPaytypeText: "Internal"
    },
    {
      payType: PAYER_TYPES.S,
      order: 4,
      checkboxLabel: "Close Service Contract",
      closedPaytypeText: "Service contract"
    }
  ];

  //* NOTE ------  used for both split payment and normal payment
  // Update payer configs with real data.
  initialPayerConfigs.forEach(config => {
    config.payer = legitPayers?.find(p => p.payType === config.payType);
    if (config.payer) {
      const hasPendingWarrantySubmissions =
        config.payType === PAYER_TYPES.W
          ? isUseOEMWarrantyEnabled
            ? quoteSummary?.quoteServices?.some(
                service =>
                  service?.payTypeCode === PAYER_TYPES.W &&
                  service?.warranty?.warrantySubmissionState !==
                    warrantySubmissionStates.READY
              )
            : quoteSummary?.quoteServices?.some(
                service =>
                  service?.payTypeCode === PAYER_TYPES.W &&
                  !service?.warranty?.warrantySubmissionState
              )
          : false;
      config.show = true;
      config.isClosed = !!config.payer.closedDate;
      config.isChecked = config.isClosed;
      config.isDisabled = config.isClosed || hasPendingWarrantySubmissions;
      config.tooltip = hasPendingWarrantySubmissions
        ? isUseOEMWarrantyEnabled
          ? warrantyBadgeLabelsNew.PENDING_WARRANTY_REVIEW
          : "Submission to OEM is pending"
        : "";
      config.showTransactionTypeDropdown =
        config.payType === PAYER_TYPES.C && !config.isClosed;
      if (config.payType === PAYER_TYPES.C && !config.payer.paymentMethod) {
        config.payer.paymentMethod = PAYMENT_TYPES.CREDIT_CARD;
      }
      if (
        config.payType === PAYER_TYPES.C &&
        !config.payer.payments?.length &&
        isSplitPaymentEnabled !== true
      ) {
        config.payer.payments = getDefaultPaymentsArray(
          config.payer.paymentMethod
        );
      }
    }
  });

  const [payerConfigs, setPayerConfigs] = useState(initialPayerConfigs);

  useEffect(() => {
    const callGetChargeAccountDetailApi = async dealerCode => {
      if (
        checkChargeAccountValid(appTypes.CSR, quoteSummary, dealerProperties)
      ) {
        setShowMask(true);
        try {
          const res = await appServices.getChargeAccountInfo(
            dealerCode,
            checkChargeAccountValid(
              appTypes.CSR,
              quoteSummary,
              dealerProperties
            )
          );
          setChargeAccountDetails(res);
          setShowMask(false);
        } catch (e) {
          console.log(e);
          //* removing charge account option if api fails
          PAYMENT_METHODS.map(
            (c, i) =>
              c.value === PAYMENT_TYPES.CHARGE && PAYMENT_METHODS.splice(i, 1)
          );
          setShowMask(false);
        }
      }
    };
    callGetChargeAccountDetailApi(quoteSummary.dealerCode);
  }, [
    quoteSummary.dealerCode,
    quoteSummary.customer.commonConsumerId,
    quoteSummary,
    dealerProperties
  ]);

  //* NOTE --  used for only normal payment
  const onContinueClicked = () => {
    const payersToUpdate = payerConfigs
      .filter(p =>
        p?.payer?.paymentMethod === PAYMENT_TYPES.CHARGE
          ? p.isChecked && p.needsSaving && allowCharge
          : p.isChecked && p.needsSaving
      )
      .map(p => {
        return {
          ...p.payer,
          paymentStatus: "closed",
          closedDate: moment().utc().format("YYYY-MM-DDTHH:mm:ss") + "+0000"
        };
      });

    const pendingChargeAccountPayments = payersToUpdate.flatMap(payer => {
      return payer.payments?.filter(
        payment => payment.paymentMethod === PAYMENT_TYPES.CHARGE
      );
    });
    pendingChargeAccountPayments.forEach(payment => {
      const requiresOverride =
        !chargeAccountDetails?.isInGoodStanding ||
        chargeAccountDetails?.availableCredit?.amount < payment.paymentAmount;
      if (requiresOverride && allowCharge) {
        payment.isOverride = true;
        payment.overrideUserId = appContext?.user?.quoteUserId;
      }
    });

    continueHandler({
      mileageOut,
      payersToUpdate,
      diffOps: { ops: [], payerId: null }
    });
  };

  const getPaymentLinesToSave = isCloseClick => {
    let payersToUpdateWithOutZeroBalance = null;

    if (isCloseClick) {
      payersToUpdateWithOutZeroBalance = payerConfigs?.find(
        p => p.payType === PAYER_TYPES.C && p.isChecked && p.needsSaving
      );
    } else {
      payersToUpdateWithOutZeroBalance = payerConfigs?.find(
        p => p.payType === PAYER_TYPES.C && !checkCustomerPayBalanceZero(p)
      );
    }
    const diffOps = { payerid: null, ops: [] };
    if (payersToUpdateWithOutZeroBalance) {
      const custPay = legitPayers?.find(pay => pay.payType === PAYER_TYPES.C);

      const formattedCustPayerPayments = custPay?.payments?.map(pay =>
        parsePayLineObject(pay)
      );

      const formattedPayersToUpdateWithOutZeroBalance =
        payersToUpdateWithOutZeroBalance?.payer?.payments?.map(pay =>
          parsePayLineObject(pay)
        );

      diffOps.ops = compare(
        formattedCustPayerPayments,
        formattedPayersToUpdateWithOutZeroBalance
      );
      // Adjust paths to include "payments" prefix
      diffOps?.ops?.map(
        operation => (operation.path = "/payments" + operation.path)
      );
      diffOps.payerId = payersToUpdateWithOutZeroBalance?.payer?.payerId;
    }
    return diffOps;
  };

  //* NOTE --  used for only split payment
  const onSplitPaymentsContinueClicked = () => {
    const payersToUpdate = payerConfigs
      .filter(p =>
        p?.payType === PAYER_TYPES.C
          ? (p?.payer?.finalPrice == 0 && p?.payer?.closedDate === null) ||
            checkCustomerPayBalanceZero(p)
          : p.isChecked && p.needsSaving
      )
      .map(p => {
        let paymentStatus = null;
        let closedDate = null;
        let paymentMethod = null;
        paymentStatus = checkCustomerPayBalanceZero(p) ? "closed" : null;
        closedDate = checkCustomerPayBalanceZero(p)
          ? moment().utc().format("YYYY-MM-DDTHH:mm:ss") + "+0000"
          : null;
        if (p.payType === PAYER_TYPES.C) {
          p.payer.payments.forEach(payment => {
            if (payment.paymentMethod === PAYMENT_TYPES.CHARGE) {
              if (payment.allowCharge === true) {
                const requiresOverride =
                  !chargeAccountDetails?.isInGoodStanding ||
                  chargeAccountDetails?.availableCredit?.amount <
                    payment.paymentAmount;
                if (requiresOverride) {
                  payment.isOverride = true;
                  payment.overrideUserId = appContext?.user?.quoteUserId;
                  delete payment.allowCharge;
                }
              }
            }
            if (payment.paymentMethod === PAYMENT_TYPES.CREDIT_CARD) {
              payment.isGpi = false;
            }
            if (payment.localPaymentId === payment.paymentId)
              payment.paymentId = null;
            delete payment.localPaymentId;
          });
          paymentMethod = PAYMENT_TYPES.SPLIT; //* hack for some time till decide on what to do for split payment
        } else {
          paymentStatus = "closed";
          closedDate = moment().utc().format("YYYY-MM-DDTHH:mm:ss") + "+0000";
        }
        return {
          ...p.payer,
          paymentStatus,
          closedDate,
          paymentMethod
        };
      });

    const diffOps = getPaymentLinesToSave(false);
    continueHandler({
      mileageOut,
      payersToUpdate,
      diffOps
    });
  };

  const deletePaymentLines = async (payLine, payLineIndex, payerId) => {
    const diffOps = {
      payerId,
      ops: [
        {
          op: "remove",
          path: `/payments/${payLineIndex}`
        }
      ]
    };
    try {
      setShowMask(true);

      await updatePaymentLines(diffOps, true);
      setShowMask(false);
    } catch (e) {
      console.log(e);
      setShowMask(false);
    }
  };

  const parsePayLineObject = payLine => {
    payLine.paymentId =
      payLine?.localPaymentId && payLine?.localPaymentId === payLine.paymentId
        ? null
        : payLine.paymentId;
    switch (payLine?.paymentMethod) {
      case PAYMENT_TYPES.CASH:
        return {
          paymentId: payLine?.paymentId,
          paymentMethod: payLine?.paymentMethod,
          drawerNumber: payLine?.drawerNumber,
          paymentAmount: payLine?.paymentAmount,
          notes: payLine?.notes
        };
      case PAYMENT_TYPES.CREDIT_CARD:
        return {
          paymentId: payLine?.paymentId,
          paymentMethod: payLine?.paymentMethod,
          transactionCode: payLine?.transactionCode,
          paymentAmount: payLine?.paymentAmount,
          provider: payLine?.provider,
          isGpi: false,
          notes: payLine?.notes
        };
      case PAYMENT_TYPES.CHECK:
        return {
          paymentId: payLine?.paymentId,
          paymentMethod: payLine?.paymentMethod,
          drawerNumber: payLine?.drawerNumber,
          checkNumber: payLine?.checkNumber,
          paymentAmount: payLine?.paymentAmount,
          notes: payLine?.notes
        };
      case PAYMENT_TYPES.CHARGE:
        if (payLine.allowCharge === true) {
          const requiresOverride =
            !chargeAccountDetails?.isInGoodStanding ||
            chargeAccountDetails?.availableCredit?.amount <
              payLine.paymentAmount;
          if (requiresOverride) {
            payLine.isOverride = true;
            payLine.overrideUserId = appContext?.user?.quoteUserId;
          }
        }
        return {
          paymentId: payLine?.paymentId,
          paymentMethod: payLine?.paymentMethod,
          paymentAmount: payLine?.paymentAmount,
          isOverride: payLine?.isOverride,
          overrideUserId: payLine?.overrideUserId,
          notes: payLine?.notes
        };
      case PAYMENT_TYPES.DEALER_POLICY:
        return {
          paymentId: payLine?.paymentId,
          paymentMethod: payLine?.paymentMethod,
          paymentAmount: payLine?.paymentAmount,
          internalAccount: payLine?.internalAccount,
          notes: payLine?.notes
        };
    }
  };

  //* NOTE --  used for only normal payment
  const canContinue = () => {
    return (
      mileageOut &&
      !checkMileageOutLessThanMileageIn() &&
      checkPaymentFieldsFilled() &&
      payerConfigs.some(p =>
        (p?.payer?.paymentMethod === PAYMENT_TYPES.CHARGE) === true
          ? p.isChecked && p.needsSaving && allowCharge
          : p.isChecked && p.needsSaving
      )
    );
  };

  //* NOTE --  used for only split payment
  const canContinueSplitPayments = () => {
    const payTypeEnabled = payerConfigs.some(p => {
      if (p.payType !== "C") {
        return p.isChecked && p.needsSaving && !p.isClosed;
      } else {
        return checkCustomerPayBalanceZero(p) && !p.isClosed;
      }
    });
    const result =
      mileageOut &&
      !checkMileageOutLessThanMileageIn() &&
      checkSplitPaymentFieldsFilled() &&
      payTypeEnabled;
    return result;
  };

  //* NOTE --  used for only normal payment
  const checkPaymentFieldsFilled = () => {
    let result = true;
    const custPayersToUpdate = payerConfigs.find(
      p => p.payType === PAYER_TYPES.C && p.isChecked && p.needsSaving
    );
    if (custPayersToUpdate) {
      result =
        custPayersToUpdate?.payer?.payments?.some(p => {
          if (p.paymentMethod === PAYMENT_TYPES.CASH) {
            return (
              !isEmpty(p.drawerNumber) &&
              !isEmpty(p.paymentAmount) &&
              !(
                parseFloat(p.paymentAmount) <
                custPayersToUpdate?.payer?.finalPrice
              )
            );
          } else if (p.paymentMethod === PAYMENT_TYPES.CHECK) {
            return !isEmpty(p.drawerNumber) && !isEmpty(p.checkNumber);
          } else {
            return true;
          }
        }) ?? true;
    }
    return result;
  };

  //* NOTE --  used for only split payment
  const checkSplitPaymentFieldsFilled = () => {
    let result = true;
    const custPayersToUpdate = payerConfigs.find(
      p => p.payType === PAYER_TYPES.C && p.isChecked && p.needsSaving
    );
    if (custPayersToUpdate) {
      result =
        custPayersToUpdate?.payer?.payments?.every(p => {
          if (p.paymentMethod === PAYMENT_TYPES.CASH) {
            return (
              !isEmpty(p.drawerNumber) &&
              !isEmpty(
                p.paymentAmount && parseFloat(p.paymentAmount) != 0
                  ? String(p.paymentAmount)
                  : null
              )
            );
          } else if (p.paymentMethod === PAYMENT_TYPES.CHECK) {
            // ! NOTE validation for uniques check number is not requried as of now
            // const isDuplicateCheckNumber =
            //   custPayersToUpdate?.payer?.payments?.some(
            //     pay =>
            //       pay?.localPaymentId !== p?.localPaymentId &&
            //       pay?.checkNumber === p.checkNumber
            //   );
            return (
              !isEmpty(p.drawerNumber) &&
              !isEmpty(p.checkNumber) &&
              !isEmpty(
                p.paymentAmount && parseFloat(p.paymentAmount) != 0
                  ? String(p.paymentAmount)
                  : null
              )
            );
          } else if (p.paymentMethod === PAYMENT_TYPES.CHARGE) {
            const requrieAllowCharge =
              !chargeAccountDetails?.isInGoodStanding ||
              chargeAccountDetails?.availableCredit?.amount < p.paymentAmount;
            return (
              !isEmpty(
                p.paymentAmount && parseFloat(p.paymentAmount) != 0
                  ? String(p.paymentAmount)
                  : null
              ) && (requrieAllowCharge ? p.allowCharge : true)
            );
          } else if (p.paymentMethod === PAYMENT_TYPES.CREDIT_CARD) {
            return (
              !isEmpty(p.provider) &&
              !isEmpty(p.transactionCode) &&
              !isEmpty(
                p.paymentAmount && parseFloat(p.paymentAmount) != 0
                  ? String(p.paymentAmount)
                  : null
              )
            );
          } else if (p.paymentMethod === PAYMENT_TYPES.DEALER_POLICY) {
            return (
              !isEmpty(p.internalAccount) &&
              !isEmpty(
                p.paymentAmount && parseFloat(p.paymentAmount) != 0
                  ? String(p.paymentAmount)
                  : null
              )
            );
          } else {
            return true;
          }
        }) ?? true;
    }

    return result;
  };

  //* NOTE --  used for only split payment
  const checkCustomerPayBalanceZero = customerPayer => {
    let collectedAmount = 0;
    customerPayer?.payer?.payments?.map(
      line =>
        (collectedAmount =
          collectedAmount +
          parseFloat(
            line?.paymentAmount !== null && line?.paymentAmount !== ""
              ? line?.paymentAmount
              : 0
          ))
    );
    const amountDue = parseFloat(customerPayer?.payer?.finalPrice?.toFixed(2));
    const balanceDue = amountDue - collectedAmount.toFixed(2);

    return balanceDue == 0.0;
  };

  const updateMileageOut = cxEvent => {
    setMileageOut(cxEvent.target.value);
  };

  //* NOTE ------  used for both split payment and normal payment
  const updatePayerChecked = (payType, isChecked) => {
    const updatedPayerConfigs = cloneDeep(payerConfigs);
    const payerConfig = updatedPayerConfigs.find(p => p.payType === payType);
    const initialConfig = initialPayerConfigs.find(p => p.payType === payType);
    Object.assign(payerConfig, {
      ...payerConfig,
      isChecked,
      needsSaving: !initialConfig.isChecked && isChecked
    });
    setPayerConfigs(updatedPayerConfigs);
  };

  //* NOTE --  used for only split payment
  const updateCustomerPayerChecked = (selectedPaymentLines, isChecked) => {
    const updatedPayerConfigs = cloneDeep(payerConfigs);
    const payerConfig = updatedPayerConfigs.find(p => p.payType === "C");
    payerConfig.payer.payments = selectedPaymentLines;
    Object.assign(payerConfig, {
      ...payerConfig,
      isChecked,
      needsSaving: isChecked
    });
    setPayerConfigs(updatedPayerConfigs);
  };

  //* NOTE --  used for only normal payment
  const updatePaymentMethod = (payType, paymentMethod) => {
    const updatedPayerConfigs = cloneDeep(payerConfigs);
    const payerConfigIndex = updatedPayerConfigs.findIndex(
      p => p.payType === payType
    );
    const payerConfig = updatedPayerConfigs[payerConfigIndex];
    payerConfig.payer.paymentMethod = paymentMethod;
    payerConfig.payer.payments = getDefaultPaymentsArray(paymentMethod);

    if (paymentMethod === PAYMENT_TYPES.CHARGE) {
      //* updating paymentAmount
      payerConfig.payer.payments[0].paymentAmount =
        payerConfig.payer.finalPrice;
    }

    updatedPayerConfigs[payerConfigIndex] = payerConfig;
    setPayerConfigs(updatedPayerConfigs);
  };

  //* NOTE --  used for only normal payment
  const updatePaymentMethodValues = (payType, cxEvent, paymentMethod) => {
    const keyName = cxEvent.target.name;
    const value = cxEvent.target.value;
    const updatedPayerConfigs = cloneDeep(payerConfigs);
    const payerConfigIndex = updatedPayerConfigs.findIndex(
      p => p.payType === payType
    );
    const payerConfig = updatedPayerConfigs[payerConfigIndex];
    const payerPaymentIndex = payerConfig.payer.payments.findIndex(
      pay => pay.paymentMethod === paymentMethod
    );
    const payerPayment = payerConfig.payer.payments[payerPaymentIndex];
    payerPayment[keyName] = value;
    if (paymentMethod === PAYMENT_TYPES.CHECK)
      payerPayment.paymentAmount = payerConfig.payer.finalPrice;
    payerConfig.payer.payments[payerPaymentIndex] = payerPayment;
    updatedPayerConfigs[payerConfigIndex] = payerConfig;
    setPayerConfigs(updatedPayerConfigs);
  };

  const modalTitle = `Finalize RO ${
    quoteSummary.roNumber ?? quoteSummary.confirmationId
  }`;

  const checkMileageOutLessThanMileageIn = () => {
    const check = parseFloat(mileageOut) < parseFloat(quoteSummary?.mileageIn);
    return check;
  };

  const onHide = () => {
    const diffOps = checkSplitPaymentFieldsFilled()
      ? getPaymentLinesToSave(true)
      : null;
    closeHandler(diffOps);
  };
  return (
    <ModalDialog
      htmlId="FinalizeROModal"
      className="finalize-ro-modal"
      show={show}
      header={<h4 className="modal-title">{modalTitle}</h4>}
      footer={
        <div>
          <Button
            htmlId="finalizeROContinueButton"
            onClick={
              showFinalizeModal && isSplitPaymentEnabled
                ? printHandler
                : isSplitPaymentEnabled
                ? onSplitPaymentsContinueClicked
                : onContinueClicked
            }
            buttonStyle="primary"
            disabled={
              !showFinalizeModal
                ? isSplitPaymentEnabled
                  ? !canContinueSplitPayments()
                  : !canContinue()
                : false
            }
          >
            {showFinalizeModal ? "Print" : "Continue"}
          </Button>
        </div>
      }
      onHide={onHide}
    >
      {showMask ? (
        <LoadingIndicator htmlId="finalizeRoLoader" />
      ) : (
        <>
          {isSplitPaymentEnabled && !showFinalizeModal ? (
            <Alert htmlId="dangerAlert" type="warning">
              Service lines cannot be changed after finalization
            </Alert>
          ) : null}

          {!isSplitPaymentEnabled ? (
            <div className="subheader">
              Prior to closing, please ensure all service lines have the correct
              pay type selected, as they cannot be changed after any pay type
              has been closed.
            </div>
          ) : null}

          <div className="mileage">
            <NumericInput
              htmlId="mileageIn"
              name="mileageIn"
              label="Mileage in"
              disabled={true}
              autoInsertCommas={true}
              onChange={() => {}}
              value={format.decimalInput(quoteSummary.mileageIn, 0)}
            />
            <NumericInput
              htmlId="mileageOut"
              name="mileageOut"
              label="Mileage out"
              disabled={isFinalized}
              autoInsertCommas={true}
              onChange={updateMileageOut}
              value={format.decimalInput(mileageOut, 0)}
              autoFocus={true}
              maxLength={6}
              minValue={quoteSummary.mileageIn ?? 0}
              error={
                checkMileageOutLessThanMileageIn()
                  ? "Mileage Out value cannot be less than mileage In value"
                  : ""
              }
              autoComplete="off"
            />
          </div>
          {isSplitPaymentEnabled ? (
            <SplitPaymentComponent
              payerConfigs={payerConfigs}
              updatePayerChecked={updatePayerChecked}
              chargeAccountDetails={chargeAccountDetails}
              updateCustomerPayerChecked={updateCustomerPayerChecked}
              legitPayers={legitPayers}
              quoteSummary={quoteSummary}
              deletePaymentLines={deletePaymentLines}
              dealerProperties={dealerProperties}
            />
          ) : (
            <div className="payer-options">
              {payerConfigs
                .filter(pc => pc.show)
                .map(payerConfig => {
                  const { payType, payer, isClosed, isDisabled, tooltip } =
                    payerConfig;
                  return (
                    <div
                      className={`payer ${isClosed ? "closed" : ""}`}
                      key={`payer${payType}`}
                    >
                      <input
                        type="checkbox"
                        checked={payerConfig.isChecked}
                        value={payType}
                        onChange={cxEvent => {
                          updatePayerChecked(payType, cxEvent.target.checked);
                        }}
                        id={`payerCheckbox${payType}`}
                        name={`payerCheckbox${payType}`}
                        disabled={isDisabled}
                        title={tooltip}
                      />
                      <label
                        htmlFor={`payerCheckbox${payType}`}
                        className={`payerCheckboxLabel ${
                          isDisabled ? "disabled" : ""
                        }`}
                        title={tooltip}
                      >
                        {payerConfig.checkboxLabel} -{" "}
                        {format.currency(payer.finalPrice)}
                      </label>
                      {payerConfig.showTransactionTypeDropdown ? (
                        <div className="transaction-type--container">
                          <div className="transaction-type">
                            <SelectInput
                              htmlId={`transactionType${payType}`}
                              name={`transactionType${payType}`}
                              label="Transaction type"
                              onChange={cxEvent => {
                                updatePaymentMethod(
                                  payType,
                                  cxEvent.target.value
                                );

                                if (
                                  cxEvent.target.value === PAYMENT_TYPES.CHARGE
                                ) {
                                  setShowAvailableBalance(true);
                                  chargeAccountDetails?.isInGoodStanding ===
                                    true &&
                                    chargeAccountDetails?.availableCredit
                                      ?.amount >
                                      quoteSummary?.payers[0]?.finalPrice &&
                                    setAllowCharge(true);
                                } else {
                                  setShowAvailableBalance(false);
                                }
                              }}
                              value={payer.paymentMethod}
                              options={PAYMENT_METHODS.map(pm => {
                                return {
                                  value: pm.value,
                                  label: pm.displayText
                                };
                              })}
                              displayDeselectOption={false}
                            />
                          </div>

                          {showAvailableBalance ? (
                            <label className="available-bal">
                              Available credit:
                              <span
                                className={`margin-left-6 ${
                                  chargeAccountDetails?.availableCredit
                                    ?.amount < 0
                                    ? "negative-bal"
                                    : ""
                                }`}
                              >
                                {format.currency(
                                  chargeAccountDetails?.availableCredit?.amount
                                )}
                              </span>
                            </label>
                          ) : null}
                        </div>
                      ) : null}
                      {payer.paymentMethod === PAYMENT_TYPES.CASH &&
                      payer.paymentStatus !== "closed" ? (
                        <div className="payment-method-class">
                          <div className="cash-input-fields">
                            <TextInput
                              htmlId="drawerId"
                              name="drawerNumber"
                              label="Drawer ID"
                              className="cash-drawer-input"
                              onChange={cxEvent => {
                                updatePaymentMethodValues(
                                  payType,
                                  cxEvent,
                                  payer.paymentMethod
                                );
                              }}
                              value={payer.payments[0]?.drawerNumber ?? ""}
                              autoFocus={true}
                              maxLength={50}
                              required
                            />
                            <PriceInput
                              htmlId="amount"
                              name="paymentAmount"
                              label="Amount collected"
                              className="cash-payment-class"
                              onChange={cxEvent => {
                                updatePaymentMethodValues(
                                  payType,
                                  cxEvent,
                                  payer.paymentMethod
                                );
                              }}
                              value={
                                payer?.payments[0]?.paymentAmount?.toString() ??
                                ""
                              }
                              error={
                                !isEmpty(payer?.payments[0]?.paymentAmount) &&
                                payer?.payments[0]?.paymentAmount <
                                  payer?.finalPrice
                                  ? "Amount cannot be less than the balance due"
                                  : ""
                              }
                              required
                            />
                          </div>
                          <div className="payment-cash-values">
                            <div className="payment-cash-span">
                              <span className="payment-text-service-due">
                                Balance due {"  "}
                              </span>
                              <span className="payment-value">
                                ${payer?.finalPrice?.toFixed(2)}
                              </span>
                            </div>
                            <div className="payment-cash-span">
                              <span className="payment-text-amount-collected">
                                Amount collected
                              </span>
                              <span className="payment-value">
                                {payer.payments.length > 0
                                  ? formatPrice(
                                      payer?.payments[0]?.paymentAmount
                                    )
                                  : null}
                              </span>
                            </div>
                            <div className="payment-cash-span">
                              <span className="payment-text-change-due">
                                Change due
                              </span>
                              <span className="payment-value-change-due">
                                {formatPrice(
                                  (payer.payments.length > 0 &&
                                    payer?.finalPrice -
                                      payer?.payments[0]?.paymentAmount <
                                      0 &&
                                    Math.abs(
                                      payer?.finalPrice -
                                        payer?.payments[0]?.paymentAmount
                                    )?.toFixed(2)) ||
                                    0.0
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {payer.paymentMethod === PAYMENT_TYPES.CHECK &&
                      payer.paymentStatus !== "closed" ? (
                        <div className="payment-check-method-class">
                          <TextInput
                            htmlId="drawerId"
                            name="drawerNumber"
                            label="Drawer ID"
                            className="check-drawer-input"
                            onChange={cxEvent => {
                              updatePaymentMethodValues(
                                payType,
                                cxEvent,
                                payer.paymentMethod
                              );
                            }}
                            value={payer.payments[0]?.drawerNumber ?? ""}
                            autoFocus={true}
                            maxLength={50}
                            required
                          />
                          <TextInput
                            htmlId="checkNumberId"
                            name="checkNumber"
                            label="Check number"
                            className="check-payment-class"
                            onChange={cxEvent => {
                              updatePaymentMethodValues(
                                payType,
                                cxEvent,
                                payer.paymentMethod
                              );
                            }}
                            value={payer.payments[0]?.checkNumber ?? ""}
                            maxLength={50}
                            required
                          />
                        </div>
                      ) : null}
                      {payer.paymentMethod === PAYMENT_TYPES.CHARGE &&
                      payer.paymentStatus !== "closed" ? (
                        <div className="payment-charge-class margin-left-20">
                          {chargeAccountDetails?.isInGoodStanding === true ? (
                            chargeAccountDetails?.availableCredit?.amount >
                            quoteSummary?.payers[0]?.finalPrice ? (
                              <Alert htmlId="infoAlert" type="info">
                                Customer will be billed at a later date.
                              </Alert>
                            ) : (
                              <Alert htmlId="dangerAlert" type="danger">
                                Low credit limit available. Select another
                                transaction type or get approval to allow
                                charge.
                              </Alert>
                            )
                          ) : (
                            <div>
                              {chargeAccountDetails?.isInGoodStanding ===
                                false &&
                              chargeAccountDetails?.availableCredit?.amount >
                                quoteSummary?.payers[0]?.finalPrice ? (
                                <Alert htmlId="dangerAlert" type="danger">
                                  This account is on hold. Select another
                                  transaction type or get approval to allow
                                  charge.
                                </Alert>
                              ) : chargeAccountDetails?.availableCredit
                                  ?.amount <
                                quoteSummary?.payers[0]?.finalPrice ? (
                                <>
                                  <Alert htmlId="dangerAlert" type="danger">
                                    Low credit limit available. Select another
                                    transaction type or get approval to allow
                                    charge.
                                  </Alert>
                                  <Alert htmlId="dangerAlert" type="danger">
                                    This account is on hold. Select another
                                    transaction type or get approval to allow
                                    charge.
                                  </Alert>
                                </>
                              ) : null}
                            </div>
                          )}
                          {!(
                            chargeAccountDetails?.isInGoodStanding === true &&
                            chargeAccountDetails?.availableCredit?.amount >
                              quoteSummary?.payers[0]?.finalPrice
                          ) ? (
                            <>
                              <input
                                className="allow-charge-input"
                                type="checkbox"
                                checked={allowCharge}
                                value={allowCharge}
                                onChange={e => {
                                  console.log(e);
                                  setAllowCharge(e.target.checked);
                                }}
                                id="allowCharge"
                                name="allowCharge"
                              />
                              <label
                                htmlFor="allowCharge"
                                className="allow-charge-label"
                              >
                                Allow charge
                              </label>
                            </>
                          ) : null}
                        </div>
                      ) : null}

                      {isClosed ? (
                        <div className="closed-details">
                          <Badge htmlId={`payerBadge${payType}`} color="purple">
                            Closed -
                            {moment(payer.closedDate).format("MM/DD/YY")}
                          </Badge>
                          {payer.paymentMethod ? (
                            <span className="payment-method">
                              {
                                PAYMENT_METHODS.find(
                                  pm => pm.value === payer.paymentMethod
                                )?.displayText
                              }
                            </span>
                          ) : null}
                        </div>
                      ) : null}
                    </div>
                  );
                })}
            </div>
          )}
        </>
      )}
    </ModalDialog>
  );
};
export default FinalizeROModal;

FinalizeROModal.propTypes = {
  show: PropTypes.bool.isRequired,
  quoteSummary: PropTypes.object.isRequired,
  continueHandler: PropTypes.func.isRequired,
  closeHandler: PropTypes.func.isRequired
};

import React, { useState, useCallback, useEffect } from "react";
import Button from "@cx/ui/Button";
import AccordionGroup from "@cx/ui/AccordionGroup";
import isEmpty from "lodash/isEmpty";
import ButtonGroup from "../../components/ui/button-group/ButtonGroup";
import {
  formatNumberWithLetter,
  formatPrice,
  priceValueFormatter
} from "../../utils/format.util";
import {
  useServiceSearchContext,
  Actions
} from "../../state/service-search.context";
import { SelectedMenuPage } from "./views";
import "./MenusQuickFilterPage.scss";
import menuServiceUtil from "../../utils/menu-service-calculation.util";
import { sortingMenuServices } from "../../utils/sorting.util.js";
import useComponentDidMount from "../../hooks/useComponentDidMount";
import { drivingConditions } from "../../constants/quick-filters.constants";

const MenusQuickFilterPage = props => {
  const { EditServiceModule } = props;
  const { state, dispatch, ctxRequestServicePoints, ctxOnEditService } =
    useServiceSearchContext();
  const {
    vehicle,
    locale,
    selectedMenuPackage,
    servicePoints,
    payTypes,
    appConfig,
    serviceTypes
  } = state;
  const [servicePointButtonOptions, setServicePointButtonOptions] = useState(
    []
  );
  const [showEditMenu, setShowEditMenu] = useState(false);
  const [accordionExpandedConfig, setAccordionExpandedConfig] = useState({});
  const [defaultPayTypeOption, setDefaultPayTypeOption] = useState("-1");
  const [defaultServiceTypeCode, setDefaultServiceTypeCode] = useState("-1");

  useComponentDidMount(() => {
    let defaultSelectedMenuDrivingCondition = drivingConditions.NORMAL;
    if (!isEmpty(vehicle?.drivingConditions)) {
      if (vehicle.drivingConditions.length === 1) {
        defaultSelectedMenuDrivingCondition =
          vehicle.drivingConditions[0].value;
      } else if (vehicle.drivingConditions.length > 1) {
        defaultSelectedMenuDrivingCondition = !vehicle.defaultDrivingCondition
          ? drivingConditions.NORMAL
          : vehicle.defaultDrivingCondition;
      }
    }
    dispatch({
      type: Actions.SET_MENU_SELECTED_DRIVING_CONDITION,
      payload: defaultSelectedMenuDrivingCondition
    });
  });

  useEffect(() => {
    const sortedServicePoints = sortMenuPackages(servicePoints);
    createServicePointOptions(sortedServicePoints);
  }, [servicePoints]);

  const createServicePointOptions = _servicePoints => {
    const options = _servicePoints
      .map(servicePoint => {
        return {
          id: String(servicePoint.mileage),
          label: formatNumberWithLetter(servicePoint.mileage),
          rawRecord: servicePoint
        };
      })
      .sort((optionA, optionB) =>
        optionA.rawRecord.position > optionB.rawRecord.position ? 1 : -1
      );

    setServicePointButtonOptions(options);
  };

  const sortMenuPackages = menus => {
    const sortedMenus = menus.sort((optionA, optionB) =>
      optionA.position > optionB.position ? 1 : -1
    );
    return sortedMenus;
  };

  const handleButtonGroupLeftArrowClick = useCallback(
    async drivingCondition => {
      if (
        Array.isArray(servicePointButtonOptions) &&
        !isEmpty(servicePointButtonOptions) &&
        servicePointButtonOptions[0].rawRecord.mileage !== 0
      ) {
        const useExactMileage = 1;
        ctxRequestServicePoints({
          drivingCondition,
          locale,
          mileage: servicePointButtonOptions[0].rawRecord.mileage,
          useExactMileage
        });
      }
    },
    [servicePointButtonOptions, locale, ctxRequestServicePoints]
  );

  const handleButtonGroupRightArrowClick = useCallback(
    async drivingCondition => {
      if (
        Array.isArray(servicePointButtonOptions) &&
        !isEmpty(servicePointButtonOptions) &&
        servicePointButtonOptions[servicePointButtonOptions.length - 1]
          .rawRecord.mileage !== 0
      ) {
        const useExactMileage = 1;
        ctxRequestServicePoints({
          drivingCondition,
          locale,
          mileage:
            servicePointButtonOptions[servicePointButtonOptions.length - 1]
              .rawRecord.mileage,
          useExactMileage
        });
      }
    },
    [servicePointButtonOptions, locale, ctxRequestServicePoints]
  );

  const handleDrivingConditionChange = useCallback(
    async drivingCondition => {
      if (
        Array.isArray(servicePointButtonOptions) &&
        !isEmpty(servicePointButtonOptions) &&
        servicePointButtonOptions.length > 1 &&
        servicePointButtonOptions[1].rawRecord.mileage !== 0
      ) {
        const useExactMileage = 0;
        dispatch({
          type: Actions.SET_MENU_SELECTED_DRIVING_CONDITION,
          payload: drivingCondition
        });
        ctxRequestServicePoints({
          drivingCondition,
          locale,
          mileage: servicePointButtonOptions[1].rawRecord.mileage,
          useExactMileage
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [servicePointButtonOptions, locale, ctxRequestServicePoints]
  );

  const handleButtonGroupOptionClick = useCallback(
    (optionIndex, drivingCondition) => {
      if (optionIndex === 0) {
        handleButtonGroupLeftArrowClick(drivingCondition);
      } else if (optionIndex === 2) {
        handleButtonGroupRightArrowClick(drivingCondition);
      }
    },
    [handleButtonGroupLeftArrowClick, handleButtonGroupRightArrowClick]
  );

  const selectMenuHandler = async (e, menuRecord) => {
    e.stopPropagation();
    ctxOnEditService(true);

    if (!menuRecord || Object.keys(menuRecord).length === 0) return;

    // Prepare menu object
    const rawService = JSON.stringify(menuRecord);
    let menuObj = {
      ...menuRecord,
      quoteRawService: { rawService },
      services: sortingMenuServices(menuRecord.services)
    };
    // special case: add selected prop to each part
    if (!isEmpty(menuObj.services)) {
      menuServiceUtil.markSelectedForAllParts(menuObj.services);
      console.log("add selected prop to menu services", menuObj.services);
    }
    // Update menu and services with paytype values
    if (!isEmpty(payTypes)) {
      const selectedDefault = menuServiceUtil.getDefaultPayTypeForMenu(
        menuObj,
        payTypes,
        state.vehicle.make
      );

      if (!isEmpty(selectedDefault)) {
        const {
          payCode: selectedPayCode,
          description: selectedDescription,
          dealerPayTypeId
        } = selectedDefault;

        const defaultPayTypeOption = !dealerPayTypeId
          ? "-1"
          : String(dealerPayTypeId);
        setDefaultPayTypeOption(defaultPayTypeOption);
        menuObj = menuServiceUtil.updateMenuWithPayTypeChanges(
          selectedPayCode,
          selectedDescription,
          menuObj
        );
      }
    }

    if (!isEmpty(serviceTypes)) {
      const defaultSelectedServiceType = serviceTypes.find(
        service => service.serviceTypeCode === "MR"
      );
      const defaultServiceType = !defaultSelectedServiceType
        ? "-1"
        : String(defaultSelectedServiceType.dealerServiceTypeId);
      setDefaultServiceTypeCode(defaultServiceType);
      menuObj = menuServiceUtil.updateMenuWithServiceTypeCodeChanges(
        "MR",
        menuObj
      );
    }

    // Dispatch updated menu details to store
    dispatchUpdates(menuObj);

    // Update UI state
    setShowEditMenu(true);
    setAccordionExpandedConfig({});
  };

  const dispatchUpdates = menuObj => {
    // Placeholder obj in context to finally update payload to save menu in quote
    dispatch({
      type: Actions.UPDATE_MENU_DETAILS_AND_HEADER,
      payload: {
        menuPackage: menuObj,
        calculatedMenuPackage: menuServiceUtil.formatMenuPackage(menuObj),
        searchHeader: false
      }
    });
  };

  const updateLocalState = editFlag => {
    setShowEditMenu(editFlag);
  };

  const handleAccordionItemClick = optionIndex => {
    setAccordionExpandedConfig({
      ...accordionExpandedConfig,
      [optionIndex]: accordionExpandedConfig[optionIndex]
        ? !accordionExpandedConfig[optionIndex]
        : true
    });
  };

  const editPage =
    showEditMenu && !isEmpty(selectedMenuPackage) ? (
      <SelectedMenuPage
        updateLocalState={updateLocalState}
        defaultPayTypeOption={defaultPayTypeOption}
        defaultServiceTypeCode={defaultServiceTypeCode}
        EditServiceModule={EditServiceModule}
      />
    ) : null;
  const parentCls = showEditMenu ? "hide-ele" : "empty-cls";
  return (
    <>
      <div id="menusQuickFilterPageContainer" className={parentCls}>
        <div id="header">
          <ButtonGroup
            options={servicePointButtonOptions}
            onLeftArrowClick={handleButtonGroupLeftArrowClick}
            onRightArrowClick={handleButtonGroupRightArrowClick}
            onOptionClick={handleButtonGroupOptionClick}
            onDrivingConditionChange={handleDrivingConditionChange}
            vehicle={vehicle}
          />
          <div className="hide-ele">
            <Button
              htmlId="comparePackagesButton"
              type="button"
              buttonStyle="link"
              className="ss-string-link"
              size="sm"
            >
              Compare packages
            </Button>
          </div>
        </div>

        {servicePoints[1] ? (
          <AccordionGroup.Container
            htmlId="accordionGroupContainer"
            independent
          >
            {servicePoints[1].menuTypes.map((option, optionIndex) => {
              option.mileage = servicePoints[1].mileage;
              option.units = servicePoints[1].units;
              return (
                <AccordionGroup.Item
                  key={`menu-${option.id}`}
                  htmlId={`menu-${option.id}`}
                  className="accordionItem"
                  header={
                    <div className="header">
                      <span className="name">{option.description}</span>
                      <div className="options">
                        <span className="price">
                          {formatPrice(option.totalPrice)}
                        </span>
                        <div className="separator" />
                        <Button
                          htmlId={`selectMenu${option.id}`}
                          type="button"
                          size="sm"
                          onClick={e => selectMenuHandler(e, option)}
                        >
                          Select
                        </Button>
                      </div>
                    </div>
                  }
                  expanded={accordionExpandedConfig[optionIndex] || false}
                  onToggle={() => handleAccordionItemClick(optionIndex)}
                >
                  <ul>
                    {option.services.map(service => (
                      <li key={service.id}>
                        <div className="op-row">
                          <div className="op-name">{service.name}</div>
                          <span className="op-price">
                            {appConfig.isDebug
                              ? priceValueFormatter(service.price)
                              : ""}
                          </span>
                        </div>
                      </li>
                    ))}
                  </ul>
                </AccordionGroup.Item>
              );
            })}
          </AccordionGroup.Container>
        ) : null}
      </div>
      {editPage}
    </>
  );
};

export default MenusQuickFilterPage;

/* eslint-disable no-irregular-whitespace */
/**
 * The objective of this function is to perform the calculation of the complete menu package,
 *  for this it has to perform the previous calculation of all its services within the package
 */
import has from "lodash/has";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import cloneDeep from "lodash/cloneDeep";
/**
 * Sets override flags for a service based on the API response.
 *
 * @param {Object} service - The service object containing potential override flags.
 * @returns {Object} The service object with updated flags.
 */
const setFlags = service => {
  // Search flow - catalog serivce returns Expected values for price overrides {true} or field dropped in API
  // Summary flow - quote service fields returns overrides as true/false
  return {
    ...service,
    totalPriceOverridden: get(service, "priceOverride", false),
    laborPriceOverridden: get(service, "scheduledLaborPriceOverride", false),
    partsPriceOverridden: get(service, "partsPriceOverride", false)
  };
};

/**
 * Determines the final parts price for a service.
 *
 * @param {Object} service - The service object to calculate the final parts price for.
 * @returns {number} The final parts price.
 */
const getFinalPartsPrice = service => {
  if (service.partsPriceOverridden) {
    return service.totalPartsPriceOverride;
  }
  return service.calculatedTotalPartsPrice;
};

/**
 * Determines the final labor price for a service.
 *
 * @param {Object} service - The service object to calculate the final labor price for.
 * @returns {number} The final labor price.
 */
const getFinalLaborPrice = service => {
  if (service.totalPriceOverridden) {
    return service.totalPriceOverride - getFinalPartsPrice(service);
  } else if (service.laborPriceOverridden) {
    return service.totalLaborPriceOverride;
  }
  return service.calculatedLaborPrice;
};

/**
 * Sets the final prices for a service. The prices determined by this function
 * are the ones shown in the UI and used for menu package calculations.
 *
 * @param {Object} service - The service object to set the final prices for.
 * @returns {Object} The service object with updated prices.
 */
const getServiceWithCalculatedPrices = service => {
  const finalLaborPrice = getFinalLaborPrice(service);
  const finalPartsPrice = getFinalPartsPrice(service);

  const finalTotalPrice = service.totalPriceOverridden
    ? service.totalPriceOverride
    : finalLaborPrice + finalPartsPrice;

  return {
    ...service,
    finalLaborPrice,
    price: finalTotalPrice,
    partsPrice: finalPartsPrice, // 2rd time we assign price
    finalPartsPrice
  };
};

/**
 * Updates a service object's override prices based on the provided override flags.
 *
 * @param {Object} service - The service object to update.
 * @returns {Object} The service object with updated override prices.
 */
const setOverridenPrices = service => {
  const {
    laborPriceOverridden,
    partsPriceOverridden,
    totalPriceOverridden,
    scheduledLaborPrice,
    partsPrice,
    price,
    totalLaborPriceOverride: existingTotalLaborPriceOverride
  } = service;

  return {
    ...service,
    totalLaborPriceOverride:
      existingTotalLaborPriceOverride ??
      (laborPriceOverridden ? scheduledLaborPrice : null),
    totalPartsPriceOverride: partsPriceOverridden ? partsPrice : null, // 1st price
    totalPriceOverride: totalPriceOverridden ? price : null
  };
};

/**
 * Calculates the parts subtotal for a service. The subtotal is determined by summing
 * up the adjusted price (unit price multiplied by adjusted quantity) for each part.
 *
 * @param {Object} service - The menu service object containing the field {part} which has list of parts to be calculated.
 * @returns {Object} The service object with the calculated parts subtotal.
 */
const calculatePartsPrice = service => {
  const partObj = service.part || [];
  const hasParts = Array.isArray(partObj) && partObj.length > 0;

  service.calculatedTotalPartsPrice = hasParts
    ? partObj
        .map(part => (part.unitPrice || 0) * (part.adjustedQuantity || 0))
        .reduce((acc, partTotal) => acc + partTotal, 0)
    : 0;

  return service;
};

/**
 * Calculates the labor subtotal for a service. Uses the scheduled labor price if
 * there's no override, otherwise sets it to zero.
 *
 * @param {Object} service - The service object containing the labor details.
 * @returns {Object} The service object with the calculated labor subtotal.
 */
const calculateLaborPrice = service => {
  // Using scheduledLaborPrice when there's no override.
  service.calculatedLaborPrice = service.scheduledLaborPriceOverride
    ? 0
    : service.scheduledLaborPrice;

  return service;
};

/**
 * Applies calculations to a service, determining the labor and parts subtotals and
 * the overall calculated service price.
 *
 * @param {Object} service - The service object to be calculated.
 * @returns {Object} The service object with updated calculations.
 */
const addCalculation = service => {
  const updatedService = { ...service };
  Object.assign(updatedService, calculateLaborPrice(updatedService));
  Object.assign(updatedService, calculatePartsPrice(updatedService));

  updatedService.calculatedServicePrice =
    updatedService.calculatedTotalPartsPrice +
    updatedService.calculatedLaborPrice;
  return updatedService;
};

/**
 * Formats a service object by applying a series of transformations and calculations.
 *
 * @param {Object} service - The original service object to be formatted.
 *
 * @returns {Object} A new service object after applying the transformations.
 */
const applyCalculationForMenuServices = service => {
  const withFlags = setFlags(service);
  const withCalculation = addCalculation(withFlags);
  const withOverriddenPrices = setOverridenPrices(withCalculation);
  const finalMenuService = getServiceWithCalculatedPrices(withOverriddenPrices);
  return finalMenuService;
};

/**
 * Formats the services within a menu package by applying calculation logic.
 *
 * @param {Object} menu - The complete menu package containing services to be formatted.
 * @param {Array} [menu.services] - List of services associated with the menu to be formatted.
 *
 * @returns {Object} A menu package with services after applying the calculations.
 */
const formatMenuPackage = menu => {
  if (!menu) return null;

  if (has(menu, "services")) {
    menu.services = menu.services.map(service => {
      const clonedService = cloneDeep(service);
      // Temporary workaround for menu service case
      clonedService.defaultLaborRate = 0;
      return applyCalculationForMenuServices(clonedService);
    });
  }

  return menu;
};

/**
 * Updates the provided menu object with given pay type changes.
 *
 * @param {string} payCode - The pay code to set in the menu.
 * @param {string} description - The pay type description to set in the menu.
 * @param {Object} selectedMenu - The menu object to be updated.
 * @param {Array} selectedMenu.services - List of services associated with the menu.
 *
 * @returns {Object} A new menu object with updated pay type values.
 */
const updateMenuWithPayTypeChanges = (payCode, description, selectedMenu) => {
  const clonedMenu = cloneDeep(selectedMenu);

  clonedMenu.services = clonedMenu.services.map(service => ({
    ...service,
    defaultLaborRate: 0, // Temporary workaround for menu service
    payTypeCode: payCode,
    payTypeDescription: description
  }));

  clonedMenu.payTypeCode = payCode;
  clonedMenu.payTypeDescription = description;

  return clonedMenu;
};

/**
 * Updates the provided menu object with given pay type changes.
 *
 * @param {string} serviceTypeCode - The service type code to set in the menu.
 * @param {Object} selectedMenu - The menu object to be updated.
 * @returns {Object} A new menu object with updated pay type values.
 */
const updateMenuWithServiceTypeCodeChanges = (
  serviceTypeCode,
  selectedMenu
) => {
  const clonedMenu = cloneDeep(selectedMenu);

  clonedMenu.services = clonedMenu.services.map(service => ({
    ...service,
    serviceTypeCode
  }));

  clonedMenu.serviceTypeCode = serviceTypeCode;
  return clonedMenu;
};

/**
 * Retrieves the default payment type based on the provided conditions.
 *
 * @param {Object} selectedMenuPackageFromState - The selected menu package state.
 * @param {string} selectedMenuPackageFromState.defaultPayTypeCode - The default payment type code from the state.
 * @param {Array} payTypes - List of payment types available.
 * @param {string} payTypes[].payCode - The payment code of the type.
 * @param {string} payTypes[].make - The make associated with the payment type.
 * @param {boolean} payTypes[].defaultPayType - Indicates if it's the default payment type.
 * @param {string} make - The make to match against.
 *
 * @returns {Object} The matched default payment type or a default object if none found.
 * @returns {string} .payCode - The payment code.
 * @returns {string} .description - The description.
 * @returns {string} .dealerPayTypeId - The dealer payment type ID.
 */
const getDefaultPayTypeForMenu = (
  selectedMenuPackageFromState,
  payTypes,
  make
) => {
  const defaultPayTypeCode =
    selectedMenuPackageFromState.defaultPayTypeCode || "";

  if (isEmpty(payTypes)) {
    return {
      payCode: "",
      description: "",
      dealerPayTypeId: "-1"
    };
  }

  const payTypeConditions = [
    pt =>
      pt.payCode === defaultPayTypeCode &&
      pt.make === make &&
      pt.defaultPayType === true,
    pt => pt.payCode === defaultPayTypeCode && pt.defaultPayType === true,
    pt => pt.payCode === defaultPayTypeCode && pt.make === make,
    pt => pt.payCode === defaultPayTypeCode
  ];

  for (const payTypeCondition of payTypeConditions) {
    const matchedPayType = payTypes.find(payTypeCondition);
    if (matchedPayType) return matchedPayType;
  }

  return {
    payCode: "",
    description: "",
    dealerPayTypeId: "-1"
  };
};

/**
 * Calculates the total parts price from an array of services.
 *
 * @param {Array<{ finalPartsPrice: number }>} services - Array of service objects.
 * @returns {number} Total parts price.
 */
const calculatePartsSubtotal = services => {
  return services.reduce((acc, service) => acc + service.finalPartsPrice, 0);
};

/**
 * Calculates the total labor price from an array of services.
 * If package price is overridden, it returns the difference of total package price and parts subtotal.
 *
 * @param {Array<{ finalLaborPrice: number, partsPrice: number }>} services - Array of service objects.
 * @param {boolean} isPackagePriceOverriden - Flag to check if package price is overridden.
 * @param {{ totalPrice: number }} selectedMenuPackage - Object containing the overridden total price.
 * @returns {number} Total labor price.
 */
const calculateLaborSubtotal = (
  services,
  isPackagePriceOverriden,
  selectedMenuPackage
) => {
  if (isPackagePriceOverriden) {
    return selectedMenuPackage.totalPrice - calculatePartsSubtotal(services);
  }
  return services.reduce((acc, service) => acc + service.finalLaborPrice, 0);
};

/**
 * Calculates the total labor hours (in minutes) from an array of services.
 *
 * @param {Array<{ durationMins: number }>} services - Array of service objects.
 * @returns {number} Total labor hours in minutes.
 */
const calculateTotalLaborHour = services => {
  return services.reduce(
    (acc, service) => acc + Number.parseFloat(service.durationMins || 0),
    0
  );
};

/**
 * Calculates and sets the total prices and hours based on provided services.
 *
 * @param {Array<{ partsPrice: number, finalLaborPrice: number, durationMins: number }>} services - Array of service objects.
 * @param {boolean} isPackagePriceOverriden - Flag to check if package price is overridden.
 * @param {{ totalPrice: number }} selectedMenuPackage - Object containing the overridden total price.
 */
const getCalculationValuesMenuPackage = (
  services,
  isPackagePriceOverriden,
  selectedMenuPackage
) => {
  const subtotalLabor = calculateLaborSubtotal(
    services,
    isPackagePriceOverriden,
    selectedMenuPackage
  );
  const subtotalPart = calculatePartsSubtotal(services);
  const totalLaborHour = calculateTotalLaborHour(services);

  const totalPrice = isPackagePriceOverriden
    ? selectedMenuPackage.totalPrice
    : subtotalLabor + subtotalPart;

  return {
    totalPrice,
    totalLaborHour,
    subtotalLabor,
    subtotalPart
  };
};
// Usecase: All parts are included under each menu service (pre-selected)
// Note: Add {selected=true} for all parts under each menu service, since catalog menus API doesn't return this field
const markSelectedForAllParts = menuServices => {
  menuServices?.forEach(service => {
    const parts = service?.part || [];
    if (!isEmpty(parts)) {
      // @note: Parts under menu services should always be pre-selected
      parts.forEach(part => {
        part.selected = true;
      });
    }
  });
};

export default {
  applyCalculationForMenuServices,
  formatMenuPackage,
  updateMenuWithPayTypeChanges,
  updateMenuWithServiceTypeCodeChanges,
  getDefaultPayTypeForMenu,
  getCalculationValuesMenuPackage,
  markSelectedForAllParts
};

import IconMore from "@cx/ui/Icons/IconMore";
import Tippy from "@tippyjs/react";
import React, { useState } from "react";
import { PropTypes } from "prop-types";
import { isItCorePart, isPartInstock } from "../../utils/helper.util";
import isNull from "lodash/isNull";
import quoteService from "../../../features/quote-summary/services/quote.service";

// @ERP
const PartActionsCellRenderer = props => {
  const {
    data,
    quoteSummary,
    onPerformPartAction,
    showCorePartReturnModal,
    showRemovePartModal,
    checkRemoveButtonDisabled,
    isCreateSpecialOrderFlagOn
  } = props;
  const [visible, setVisible] = useState(false);
  const show = () => setVisible(true);
  const hide = () => setVisible(false);
  // ! @note: disabled until product ask for this feature
  // const [isEPChecked, setEPChecked] = useState(
  //   data.typeOfPurchase === "EP" || false
  // );
  // const [isSOChecked, setSOChecked] = useState(
  //   data.typeOfPurchase === "SO" || false
  // );
  const isApproved = !!data.approver;
  const [isApprovedChecked, setIsApprovedChecked] = useState(isApproved);

  const handleIsApprovedChecked = () => {
    setIsApprovedChecked(!isApprovedChecked);
  };
  // ! @note: disabled until product ask for this feature
  // const handleEPChange = () => {
  //   setEPChecked(!isEPChecked);
  //   setSOChecked(false);
  // };

  // const handleSOChange = () => {
  //   setSOChecked(!isSOChecked);
  //   setEPChecked(false);
  // };

  const hideCoreReturn = data => {
    let hidden =
      !isItCorePart(data?.isCorePart) ||
      data?.partPrice < 0 ||
      !isNull(data?.coreReturnId);
    if (!hidden) {
      const { parts } = partActionsGlobals;
      const returnedCoreParts = parts.filter(
        p =>
          p.oemPartNumber === data.oemPartNumber &&
          data.quoteServicePartId === p.coreReturnId
      );
      hidden = returnedCoreParts.length !== 0;
    }
    return hidden;
  };

  const isWithAdvisor = quoteSummary?.quoteStatus === "WITH_ADVISOR";

  const isPartInStock = isPartInstock(data?.quantity, data?.quantityAvailable);
  const isMissingPurchaseType =
    !isPartInStock && !data?.purchaseType && !isItCorePart(data?.isCorePart);

  const withAdvisorDisabledMessage =
    "Cannot approve parts while RO is still with advisor";
  const withoutUnitCostDisabledMessage =
    "Cannot approve part till part didn't have cost";
  const withoutTypeDisabledMessage = "Cannot approve part without type";

  const dropDownContent = (
    <div>
      <div className="pam-container">
        <div>
          <div className="pam-menu-list--item">
            <input
              className="pam-input"
              type="checkbox"
              name="A"
              checked={isApprovedChecked}
              disabled={
                isApproved ||
                isWithAdvisor ||
                data?.unitCost === null ||
                isMissingPurchaseType
              }
              title={
                (isWithAdvisor ? withAdvisorDisabledMessage : "") ||
                (data?.unitCost === null
                  ? withoutUnitCostDisabledMessage
                  : "") ||
                (isMissingPurchaseType ? withoutTypeDisabledMessage : "")
              }
              onChange={e => {
                hide();
                handleIsApprovedChecked();
                onPerformPartAction({
                  actionType: "Approve",
                  isApprovedChecked: e.target.checked,
                  isEPChecked: false,
                  isSOChecked: false,
                  quoteServicePartId: data.quoteServicePartId
                });
              }}
            />
            <label
              htmlFor="A"
              disabled={
                isApproved ||
                isWithAdvisor ||
                data?.unitCost === null ||
                isMissingPurchaseType
              }
              title={
                (isWithAdvisor ? withAdvisorDisabledMessage : "") ||
                (data?.unitCost === null
                  ? withoutUnitCostDisabledMessage
                  : "") ||
                (isMissingPurchaseType ? withoutTypeDisabledMessage : "")
              }
              className={
                isWithAdvisor ||
                isMissingPurchaseType ||
                data?.unitCost === null
                  ? "disabled"
                  : ""
              }
            >
              Approve
            </label>
          </div>
          <div
            className={
              isCreateSpecialOrderFlagOn
                ? "pam-menu-list--item hide-ele"
                : "hide-ele"
            }
          >
            <button
              className={`btn-link btn-gray ${
                data.purchaseType === "EP/Drop"
                  ? "remove-part-button-disabled"
                  : ""
              }`}
              disabled={data.purchaseType === "EP/Drop"}
              onClick={() => {
                onPerformPartAction({
                  actionType: "Commit",
                  quoteService: data
                });
              }}
            >
              Commit
            </button>
          </div>
          {/* // ! @note: disabled until product ask for this feature
          <div className="pam-hr" />
          <div className="pam-menu-list--item">
            <input
              className="pam-input"
              type="checkbox"
              name="EP"
              disabled={isApproved}
              checked={isEPChecked}
              onChange={e => {
                hide();
                handleEPChange();
                onPerformPartAction({
                  actionType: "EmergencyPurchase",
                  isEPChecked: e.target.checked,
                  typeOfPurchase: "EP",
                  quoteServicePartId: data.quoteServicePartId
                });
              }}
            />
            <label htmlFor="EP">Emergency purchase</label>
          </div>
          <div className="pam-menu-list--item">
            <input
              className="pam-input"
              type="checkbox"
              name="SO"
              disabled={isApproved}
              checked={isSOChecked}
              onChange={e => {
                hide();
                handleSOChange();
                onPerformPartAction({
                  actionType: "SpecialOrder",
                  isSOChecked: e.target.checked,
                  typeOfPurchase: "SO",
                  quoteServicePartId: data.quoteServicePartId
                });
              }}
            />
            <label htmlFor="SO">Special order</label>
          </div> */}
          <div className="pam-hr" />
          <div className="pam-menu-list--item">
            <button
              className={`btn-link btn-gray ${
                checkRemoveButtonDisabled(data).disabled
                  ? "remove-part-button-disabled"
                  : ""
              }`}
              disabled={checkRemoveButtonDisabled(data).disabled}
              title={checkRemoveButtonDisabled(data)?.disabledMessage}
              onClick={() => {
                hide();
                if (data.typeOfPurchase === "EP") {
                  showRemovePartModal({
                    isApprovedChecked,
                    typeOfPurchase: data.typeOfPurchase,
                    quoteServicePartId: data.quoteServicePartId,
                    part: data
                  });
                } else {
                  const { approver } = data;
                  onPerformPartAction({
                    actionType: "Remove",
                    quoteServicePartId: data.quoteServicePartId,
                    returnToInventory: !!approver
                  });
                }
              }}
            >
              Remove
            </button>
          </div>
          <div className="pam-menu-list--item">
            <button
              className="btn-link btn-gray"
              hidden={hideCoreReturn(data)}
              onClick={() => {
                hide();
                showCorePartReturnModal(data);
              }}
            >
              Core Return
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <Tippy
      content={dropDownContent}
      visible={visible}
      onClickOutside={hide}
      allowHTML={true}
      arrow={false}
      appendTo={document.body}
      interactive={true}
      placement="right"
    >
      <button
        className="btn-link pam-button"
        onClick={visible ? hide : show}
        size="sm"
      >
        <IconMore htmlId="parts-action-menu-iconMore" />
      </button>
    </Tippy>
  );
};

// ERP-end

export default PartActionsCellRenderer;

PartActionsCellRenderer.propTypes = {
  onPerformPartAction: PropTypes.func,
  showRemovePartModal: PropTypes.func,
  showCorePartReturnModal: PropTypes.func
};

export const partActionsGlobals = { parts: [] };

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import IconArrowUpward from "@cx/ui/Icons/IconArrowUpward";
import ConfirmPopup from "../../components/ui/modals/ConfirmPopup";
import {
  useServiceSearchContext,
  Actions
} from "../../state/service-search.context";
import serviceDataMapper from "../../utils/service-data-mapper";
import "./EditDeclinedServicePage.scss";
// import { convertHoursToMinutes } from "../../utils/quote.util";
import { saveActionTypes } from "../../constants/search.constants";

const EditDeclinedServicePage = props => {
  const { service, updateParentState, EditServiceModule } = props;
  const { dispatch, state, ctxRequestPartsInventoryCommon } =
    useServiceSearchContext();
  const { localeStrings, debugQuote, vehicle, userPermissions } = state;
  const [declinedAPIService, setDeclinedAPIService] = useState(null);
  const [showDiscardEditPopup, setShowDiscardEditPopup] = useState(false);
  const [serviceHasChanged, setServiceHasChanged] = useState(false);
  const payTypes = state.payTypes;

  useEffect(() => {
    setDeclinedAPIService(service);
  }, [service]);

  const backToService = () => {
    updateParentState(false);
    dispatch({
      type: Actions.SET_SEARCH_HEADER,
      payload: true
    });
    dispatch({
      type: Actions.SET_GLOBAL_OPERATION_DETAILS,
      payload: null
    });
    setDeclinedAPIService(null);
  };

  const handleServiceChange = serviceChanged => {
    setServiceHasChanged(serviceChanged);
  };

  const handleCancel = () => {
    if (serviceHasChanged) {
      setShowDiscardEditPopup(true);
    } else {
      backToService();
    }
  };

  const handleServiceUpdate = updatedService => {
    const newDeclinedService = serviceDataMapper.updateDeclinedService(
      declinedAPIService,
      updatedService
    );
    console.log("final edited declined", newDeclinedService);
    dispatch({
      type: Actions.SET_SAVE_ACTION_TYPE,
      payload: saveActionTypes.SAVE
    });
    dispatch({
      type: Actions.SET_SELECTED_DECLINED_SERVICE,
      payload: newDeclinedService
    });
  };

  const saveServiceAndGoBack = updatedService => {
    const newDeclinedService = serviceDataMapper.updateDeclinedService(
      declinedAPIService,
      updatedService
    );
    dispatch({
      type: Actions.SET_SAVE_ACTION_TYPE,
      payload: saveActionTypes.SAVEANOTHER
    });
    dispatch({
      type: Actions.SET_SELECTED_DECLINED_SERVICE,
      payload: newDeclinedService
    });
    backToService();
  };
  const asyncUpdatePartsByPaytype = async (paytype, editedService) => {
    declinedAPIService.payTypeCode = paytype;
    ctxRequestPartsInventoryCommon(declinedAPIService, editedService);
  };

  const discardEditPopup = (
    <ConfirmPopup
      title={localeStrings["sq.search.common.alert_lbl"]}
      message={localeStrings["sq.search.common.leaving_edit_page"]}
      show={showDiscardEditPopup}
      okText={localeStrings["sq.search.common.proceed_button"]}
      cancelText={localeStrings["sq.search.common.cancel_button"]}
      okAction={backToService}
      cancelAction={() => setShowDiscardEditPopup(false)}
      hideCancel={false}
      hideOk={false}
      buttonStyle="danger"
    />
  );

  const clonedEditServiceModule = !isEmpty(declinedAPIService)
    ? React.cloneElement(EditServiceModule, {
        // @todo-beta: rawOperationDetails - convert declinedAPIService to same datamodel like dealer publish service
        rawOperationDetails: declinedAPIService,
        debugMode: debugQuote,
        userPermissions,
        vehicle,
        service:
          serviceDataMapper.editModulePropsFromDeclinedService(
            declinedAPIService
          ),
        localeStrings,
        payTypes,
        onCancelHandler: handleCancel,
        onSaveHandler: handleServiceUpdate,
        onSaveAnotherHandler: saveServiceAndGoBack,
        onChangePaytype: asyncUpdatePartsByPaytype,
        onServiceChange: handleServiceChange
      })
    : null;

  return !isEmpty(declinedAPIService) ? (
    <div id="editDeclinedService" className="search-flex-grid-container">
      <span
        className="back-nav-label search-back-sticky"
        onClick={handleCancel}
      >
        <IconArrowUpward
          htmlId="backArrowIcon"
          isActive={false}
          className="back-arrow"
        />
        Back to results
      </span>
      {clonedEditServiceModule}
      {discardEditPopup}
    </div>
  ) : null;
};

export default EditDeclinedServicePage;

EditDeclinedServicePage.defaultProps = {
  service: null,
  updateParentState: () => {},
  onSaveDeclined: () => {},
  EditServiceModule: null
};

EditDeclinedServicePage.propTypes = {
  service: PropTypes.object.isRequired,
  updateParentState: PropTypes.func.isRequired,
  EditServiceModule: PropTypes.element
};

/* eslint-disable no-console */
import React, { Component } from "react";
import Button from "@cx/ui/Button";
import QuoteSlider from "../../../quote-slider";
import { AppContext } from "../../../../state/app-context";
import { PropTypes, func } from "prop-types";
import * as gtmEvent from "../../../utils/gtag/gtag-event.util";
import { setPendoLocation } from "../../../../utils/browser.util";
class NewQuoteBtn extends Component {
  static contextType = AppContext;
  constructor(props, context) {
    super(props, context);
    this.showSliderPage = this.showSliderPage.bind(this);
    this.closeSliderPage = this.closeSliderPage.bind(this);
    this.callbackActionHandler = this.callbackActionHandler.bind(this);
    const { appSource } = context;
    this.state = {
      showSlide: appSource === "IND" ? false : true,
      flexWidth: true,
      sliderWidth: "85vw", // @note - cx recommended to pass width in viewpoint treated as 85%
      disableAction: false
    };
  }

  /**
   * Add event listener
   */
  componentDidMount() {}

  showSliderPage = event => {
    gtmEvent.trackGAPageview("newquote");
    gtmEvent.trackGAEventWithParam("ga.dashboard.create_quote_click", {
      result: this.context.dealer.dealerCode
    });
    setPendoLocation("/newquote");
    this.context.updateQuoteParams({
      accessType: null,
      customerId: null,
      vehicleId: null,
      quoteId: null
    });
    event.preventDefault();
    this.setState(prevState => ({
      showSlide: !prevState.showSlide,
      disableAction: true
    }));
    this.context.handleSliderStatus(true);
  };

  /* Close Handler for Slider and click event outside the drawer
     use this context callback to show speed bump when Form is dirty
  */
  closeSliderPage = () => {
    this.context.handleSliderStatus(false);
    this.setState({
      showSlide: false,
      disableAction: false
    });
    this.callRefreshQuotes();
    pendo.location.useBrowserUrl();
  };
  callRefreshQuotes = () => {
    if (this.context) {
      this.context.reloadPastQuotes("NEWQUOTE");
    }
  };
  callbackActionHandler = () => {
    console.log("New Quote ok action triggered", this.context);
  };

  render() {
    const { localeStrings } = this.context;
    const { className, disabled } = this.props;

    const slider = (
      <QuoteSlider
        title={localeStrings["sq.nav.new_quote"]}
        showSlide={this.state.showSlide}
        sliderWidth={this.state.sliderWidth}
        flexWidth={this.state.flexWidth}
        closeModal={this.closeSliderPage}
        okAction={this.callbackActionHandler}
      />
    );

    return (
      <span>
        <Button
          htmlId="SliderBtn"
          buttonStyle="primary"
          className={className}
          disabled={disabled}
          onClick={this.showSliderPage}
        >
          {localeStrings["sq.newquote.new_quote.start_new_quote_button"]}
        </Button>
        {slider}
      </span>
    );
  }
}

export default NewQuoteBtn;

NewQuoteBtn.propTypes = {
  onClick: func,
  className: PropTypes.string,
  disabled: PropTypes.bool
};
NewQuoteBtn.defaultProps = {
  disabled: false
};
/* eslint-enable no-console */

import React from "react";
import { useEditServiceContext } from "../state/edit-service.context";

const ServiceDescription = () => {
  const { state } = useEditServiceContext();
  const { description } = state.service;
  return (
    <div>
      <span className="edit-global-repair-header">Description</span>
      <div className="edit-global-repair-text">
        <span data-testid="description">{description || ""}</span>
      </div>
    </div>
  );
};

export default ServiceDescription;

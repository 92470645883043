import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { AgGridReact } from "ag-grid-react";
import IconMore from "@cx/ui/Icons/IconMore";
import "../../../../theme/agtheme/grid-style.scss";

import CsrActionMenu from "../../../repair-order/components/csr-action-menu.component";
import CustomNoRowsOverlay from "../../../ui/loadingmask/CustomNoRowsOverlay";
import CustomerColumn from "./components/customer-column.component";
import { formatDate } from "./archived-repair-orders-grid.util";
import { AppContext } from "../../../../state/app-context";
import QuoteSlider from "../../../quote-slider";
import { transform3PPToQuoteSummary } from "../../utils/repair-order-details.util";
import { priceValueFormatter } from "../../../utils/format";
import DocumentsModal from "../../../repair-order/components/documents-modal/documents-modal.component";
import csrService from "../../../quote-summary/services/csr.service";
import BodyMask from "../../../ui/reusable/common/body-mask.component";

const ArchivedRepairOrdersGrid = ({
  repairOrders,
  noRowsOverlayComponentParams
}) => {
  const context = useContext(AppContext);

  const [showSlide, setShowSlide] = useState(false);
  const [documentsModal, setDocumentsModal] = useState({
    showDocumentsModal: false,
    documentsQuote: {}
  });
  const [showGridPageMask, setShowGridPageMask] = useState(false);

  async function blockUntilCompleted(asyncFunction) {
    try {
      setShowGridPageMask(true);
      await asyncFunction();
    } catch (e) {
      console.error(e);
    } finally {
      setShowGridPageMask(false);
    }
  }
  const documentsActionHandler = quote => {
    setDocumentsModal({
      documentsQuote: quote,
      showDocumentsModal: true
    });
  };
  const searchArchiveHandler = async () => {
    await blockUntilCompleted(async () => {
      setDocumentsModal(prevState => ({
        ...prevState,
        showDocumentsModal: false
      }));
      const documentsURL = await csrService.getDocumentsURL({
        dealerCode: documentsModal.documentsQuote.dealerCode,
        csrnumber: documentsModal.documentsQuote.confirmationId
      });
      window.open(documentsURL);
    });
  };
  const printSelectedDocumentsHandler = async docNames => {
    await blockUntilCompleted(async () => {
      setDocumentsModal(prevState => ({
        ...prevState,
        showDocumentsModal: false
      }));
      const quoteSummary = {
        roNumber: documentsModal?.documentsQuote?.roId,
        ...documentsModal?.documentsQuote
      };
      await csrService.printSelectedDocuments(
        documentsModal.documentsQuote.dealerCode,
        documentsModal.documentsQuote.quoteId,
        docNames,
        quoteSummary
      );
    });
  };

  const defaultColDef = {
    floatingFilter: false, // true - enable column header filters
    flex: 1,
    sortable: true,
    resizable: true,
    editable: false, // default disable editor
    enableRowGroup: true,
    suppressMenu: false,
    sortingOrder: ["asc", "desc", null],
    minWidth: 120,
    autoHeight: true,
    menuTabs: ["generalMenuTab", "filterMenuTab"],
    filter: "agTextColumnFilter",
    filterParams: { buttons: ["clear"] },
    headerClass: "ag-text-header"
  };

  const columnTypes = {
    numberColumn: {
      maxWidth: 150,
      minWidth: 120,
      filter: "agNumberColumnFilter",
      filterParams: {
        includeBlanksInEquals: false,
        includeBlanksInLessThan: false,
        includeBlanksInGreaterThan: false,
        suppressMiniFilter: true,
        buttons: ["clear"]
      }
    }
  };

  const frameworkComponents = {
    csrActionMenu: CsrActionMenu,
    customNoRowsOverlay: CustomNoRowsOverlay
  };

  const colDefs = [
    {
      /* RO */
      field: "roId",
      headerName: "RO",
      headerClass: "ag-text-header",
      pinned: "left",
      sortable: true,
      minWidth: 120,
      width: 150,
      maxWidth: 150,
      cellClass: "xmm-link-cell"
    },
    {
      /* Service Type codes */
      field: "serviceTypeCodes",
      headerName: "Service types",
      headerClass: "ag-text-header",
      pinned: "left",
      minWidth: 120,
      width: 150,
      maxWidth: 150
    },
    {
      /* Date */
      field: "finalDate",
      headerName: "Date",
      headerClass: "ag-text-header",
      minWidth: 120,
      width: 150,
      maxWidth: 150,
      valueFormatter: params => formatDate(params.value)
    },
    {
      /* Customer */
      field: "customerName",
      headerName: "Customer",
      sortable: true,
      minWidth: 150,
      width: 200,
      cellClass: "xmm-wrap-text",
      cellRendererFramework: params => {
        return params.data?.repairOrder?.customer ? (
          <CustomerColumn customer={params.data.repairOrder.customer} />
        ) : (
          "- -"
        );
      }
    },
    {
      /* Vehicle */
      field: "repairOrder.vehicle",
      headerName: "Vehicle",
      sortable: true,
      minWidth: 150,
      width: 200,
      cellClass: "xmm-wrap-text",
      valueFormatter: params => {
        const year = params.data?.repairOrder?.vehicle?.description?.year ?? "";
        const make = params.data?.repairOrder?.vehicle?.description?.make ?? "";
        const model =
          params.data?.repairOrder?.vehicle?.description?.model
            ?.marketingName ?? "";
        if (!year && !make && !model) return "- -";
        return `${year} ${make} ${model}`;
      }
    },
    {
      /* Advisor */
      field: "repairOrder.advisor",
      headerName: "Advisor",
      sortable: true,
      minWidth: 150,
      width: 200,
      cellClass: "xmm-wrap-text",
      filter: "agSetColumnFilter",
      tooltipField: "advisor",
      tooltipComponentParams: { field: "advisor" },
      valueFormatter: params => {
        const firstName = params.data?.repairOrder?.advisorFirstName ?? "";
        const lastName = params.data?.repairOrder?.advisorLastName ?? "";
        if (!firstName && !lastName) return "- -";
        return `${firstName} ${lastName}`;
      }
    },
    {
      /* Services */
      field: "services",
      headerName: "Services",
      headerClass: "ag-text-header",
      cellClass: "xmm-wrap-cell",
      type: "numberColumn",
      suppressSizeToFit: true,
      minWidth: 130,
      width: 130,
      maxWidth: 130
    },
    {
      field: "totalPrice",
      headerName: "Total $",
      valueFormatter: params => {
        return (
          priceValueFormatter(params.data?.repairOrder?.totalPrice?.amount) ||
          "- -"
        );
      }
    },
    {
      /* VIN */
      field: "vin",
      headerName: "VIN",
      sortable: true,
      minWidth: 120,
      width: 120,
      maxWidth: 120,
      cellClass: "xmm-wrap-text",
      tooltipField: "vin",
      cellRendererFramework: params =>
        params.value
          ? `...${params?.value.substring(params?.value.length - 7)}`
          : "- -"
    },
    {
      /* Hang tag */
      field: "repairOrder.hangtag",
      headerName: "Tag",
      sortable: true,
      minWidth: 100,
      width: 100,
      maxWidth: 100,
      cellClass: "xmm-wrap-text",
      valueFormatter: params => {
        return params.value ? params.value : "- -";
      }
    },
    {
      /* Pay Type codes */
      field: "payTypeCodes",
      headerName: "P",
      sortable: true,
      minWidth: 100,
      width: 100,
      maxWidth: 100,
      cellClass: "xmm-wrap-text",
      filter: "agSetColumnFilter"
    },
    {
      // Action Menu
      headerName: "",
      pinned: "right",
      resizable: false,
      sortable: false,
      minWidth: 65,
      width: 65,
      maxWidth: 65,
      editable: true,
      cellClass: "editable-cell",
      cellEditorParams: {
        parentHandle: {
          editActionHandler: repairOrder => {
            openSlider(repairOrder);
          },
          documentsActionHandler: repairOrder => {
            setDocumentsModal({
              documentsQuote: repairOrder,
              showDocumentsModal: true
            });
          }
        }
      },
      cellEditorSelector: () => {
        return { component: "csrActionMenu" };
      },
      cellRendererFramework: () => {
        // TODO: cellRendererFramework is deprecated in AG Grid 27, cellRenderer can handle React now
        return (
          <div className="grid-action-menu-renderer">
            <IconMore isActive={true} />
          </div>
        );
      },
      filter: false,
      suppressSizeToFit: true,
      suppressMenu: true,
      suppressColumnsToolPanel: true
    }
  ];

  const sideBar = {
    toolPanels: [
      {
        id: "columns",
        labelDefault: "Columns",
        labelKey: "columns",
        iconKey: "columns",
        toolPanel: "agColumnsToolPanel",
        toolPanelParams: {
          suppressPivots: true,
          suppressPivotMode: true,
          suppressValues: true,
          suppressRowGroups: false
        }
      },
      {
        id: "filters",
        labelDefault: "Filters",
        labelKey: "filters",
        iconKey: "filter",
        toolPanel: "agFiltersToolPanel"
      }
    ],
    position: "left"
  };

  const statusBar = {
    statusPanels: [
      {
        statusPanel: "agTotalAndFilteredRowCountComponent",
        align: "left"
      },
      {
        statusPanel: "agFilteredRowCountComponent"
      },
      {
        statusPanel: "agSelectedRowCountComponent",
        align: "left"
      }
    ]
  };

  const openSlider = record => {
    const enrichedCustomer = {
      ...record.repairOrder.customer,
      customerId: record.customerId
    };
    const enrichedRecord = {
      ...record,
      repairOrder: { ...record.repairOrder, customer: enrichedCustomer }
    };
    const quoteSummary = transform3PPToQuoteSummary(enrichedRecord);
    const { vehicleId, customerId, confirmationId } = quoteSummary;
    const quoteParams = {
      accessType: !confirmationId ? null : "EDIT",
      quoteId: confirmationId,
      vehicleId,
      customerId,
      repairOrder: quoteSummary,
      sourceSystem: record.repairOrder?.audit?.sourceSystem
    };
    context.updateQuoteParams(quoteParams);

    setShowSlide(true);
    context.handleSliderStatus(true);
  };

  const closeSlider = () => {
    setShowSlide(false);
    pendo.location.useBrowserUrl();
  };

  const onCellClickedEvent = params => {
    const { field } = params.colDef;

    if (field === "roId") {
      openSlider(params.data);
    }
  };

  return (
    <div id="grid-wrapper">
      <div
        id="archivedRepairOrdersGrid"
        className="ag-grid-container ag-theme-alpine archived-quotes-grid"
      >
        <AgGridReact
          rowData={repairOrders}
          defaultColDef={defaultColDef}
          columnDefs={colDefs}
          sideBar={sideBar}
          columnTypes={columnTypes}
          frameworkComponents={frameworkComponents}
          enterMovesDownAfterEdit={true}
          enterMovesDown={true}
          enableRangeSelection={false}
          enableCellTextSelection={true}
          // @note: true - use browser default tooltip instead of ag-grid tooltip
          enableBrowserTooltips={true}
          suppressMenuHide={false}
          suppressContextMenu={true}
          suppressRowClickSelection={true}
          singleClickEdit={true}
          animateRows={true}
          onCellClicked={onCellClickedEvent}
          noRowsOverlayComponent="customNoRowsOverlay"
          noRowsOverlayComponentParams={noRowsOverlayComponentParams}
          groupUseEntireRow={true}
          statusBar={statusBar}
        />
        <QuoteSlider
          title="RO Detail"
          showSlide={showSlide}
          sliderWidth="85vw"
          flexWidth={true}
          closeModal={closeSlider}
        />
      </div>
      {showGridPageMask ? <BodyMask loadingText="" /> : null}
      {documentsModal.showDocumentsModal ? (
        <DocumentsModal
          show={documentsModal.showDocumentsModal}
          quoteSummary={documentsModal.documentsQuote}
          closeHandler={() => {
            setDocumentsModal(prevState => ({
              ...prevState,
              showDocumentsModal: false
            }));
          }}
          searchArchiveHandler={searchArchiveHandler}
          documentsActionHandler={documentsActionHandler}
          printSelectedDocumentsHandler={printSelectedDocumentsHandler}
        />
      ) : null}
    </div>
  );
};

ArchivedRepairOrdersGrid.propTypes = {
  repairOrders: PropTypes.arrayOf(
    PropTypes.shape({
      vin: PropTypes.string,
      customerName: PropTypes.string,
      services: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      serviceTypeCodes: PropTypes.string,
      payTypeCodes: PropTypes.string,
      repairOrder: PropTypes.shape({
        hangtag: PropTypes.string,
        advisor: PropTypes.string,
        customer: PropTypes.shape({
          name: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.shape({
              firstName: PropTypes.string,
              lastName: PropTypes.string
            })
          ]),
          emails: PropTypes.arrayOf(
            PropTypes.shape({
              email: PropTypes.string
            })
          )
        }),
        services: PropTypes.arrayOf(
          PropTypes.shape({
            serviceType: PropTypes.shape({
              code: PropTypes.string
            }),
            payTypeCode: PropTypes.string
          })
        )
      })
    })
  ),
  noRowsOverlayComponentParams: PropTypes.shape({
    noRowsMessage: PropTypes.string,
    apiStatus: PropTypes.string
  })
};

export default ArchivedRepairOrdersGrid;

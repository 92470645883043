/* eslint-disable no-console */
/* Create Axios instance here and use transformer for request and response data to parse and bind to UI */
import axios from "axios";
import isNumber from "lodash/isNumber";
import jwtDecode from "jwt-decode";
import { toast } from "@cx/ui/Toast";
import * as gtag from "../features/utils/gtag/gtag-manager.util";
import * as gtmEvent from "../features/utils/gtag/gtag-event.util";
import { httpCodes } from "../utils/http-status-codes.util";
import { xlate } from "../utils/i18n/locales";
import uiConfig from "./config/hosts.config.json";
import { queryStringToJSON } from "./app.util";
import { isEmptyObject } from "../utils/object";
import { appTypes } from "../constants/app.constants";
import get from "lodash/get";
import eventBus from "./event-bus";

const MAX_REFRESH_CALLS = 3;
const dealerApiUrl = "/ops/dealerapi/settings";
const userApiUrl = "/ops/userapi/getUserDetails";
const NETWORK_ERROR = "Network Error";
let xmmAxios = null;
const globalAbortController = newAbortController();
const isCSR = appTypes.CSR;
const isSQ = appTypes.SQ;

const hostname = window.location.hostname;

// global variables exported
export const DEFAULT_LOCALE = "en_US";
export let portalUrl = window.location.origin;
export let baseLoginUrl = "";
export let baseNodeJsPrintUrl = "";
export let baseImgUrl = "";
export let appType = null;
export let baseAppConfig = null;

const tokens = hostname.split(".");
export let namespace = tokens[1];
// @csr-logic
export function getAppType(appTypeFromUrl) {
  const hostname = window.location.hostname;
  appType =
    hostname.indexOf("csr.") !== -1 || hostname.indexOf("servicero.") !== -1
      ? isCSR
      : hostname.indexOf("servicequoting.") !== -1
      ? isSQ
      : null;
  if (!appType && appTypeFromUrl === isCSR) {
    appType = isCSR;
  } else if (!appType && appTypeFromUrl === isSQ) {
    appType = isSQ;
  }
  return appType;
}
// UI PORTAL DOMAIN URLs
const QUOTING_DOMAIN_PROD_US = "servicequoting.xtime.com";
const QUOTING_DOMAIN_PROD_NEW = "servicequoting.xtime.app.coxautoinc.com";
// const QUOTING_DOMAIN_PROD_AU = "servicequoting.xtime.net.au";
const CSR_DOMAIN_PROD_US = "servicero.xtime.com";
const CSR_DOMAIN_PROD_NEW = "servicero.xtime.app.coxautoinc.com";
// const CSR_DOMAIN_PROD_AU = "servicero.xtime.net.au";
if (
  hostname.indexOf(QUOTING_DOMAIN_PROD_US) !== -1 ||
  hostname.indexOf(CSR_DOMAIN_PROD_US) !== -1
) {
  namespace = "prod";
} else if (
  hostname.indexOf(QUOTING_DOMAIN_PROD_NEW) !== -1 ||
  hostname.indexOf(CSR_DOMAIN_PROD_NEW) !== -1
) {
  namespace = "prod";
  // to support AU domain, use namespace = "prodaus";
} else if (hostname.indexOf("localhost") !== -1) {
  // API ENV for LOCALHOST: set default namespace as `dev6` or `qa6` when running APIs in local pointing to specific Environment
  namespace = "dev6";
}

// console.log("NAMESPACE", namespace);
// TODO: This method called to set default URLs reading from UIConfig.json, before updating with QuoteUiConfig rest API
export function initializeUiConfig(uiConfig) {
  // const hostname = window.location.hostname;
  if (hostname.indexOf("localhost") !== -1) {
    portalUrl = "http://localhost:8080/rest";
  } else {
    portalUrl =
      appType === isCSR
        ? uiConfig[namespace].csrApi
        : uiConfig[namespace].serviceQuotingApi;
  }
}
// Method should return loginUrl for standalone app and iframe embedded app cases
//
export function buildXTLoginUrl(appConfig) {
  // PROD case: env should be empty to build XT loginUrl
  const urlEnv = appConfig.appEnv === "prod" ? "" : appConfig.appEnv;
  // by default, read loginUrl from API
  let xtLoginUrl = !appConfig.loginURL ? "" : appConfig.loginURL;
  try {
    // check hostname is either coxautoinc.com or xtime.com - common for both apps
    // Note: document referrer gives parent hostname (iframe), self hostname(standalone)
    const domainRegex = /(coxautoinc.com)/g;
    let hostname = !document.referrer
      ? document.location.hostname
      : document.referrer;
    hostname = String(hostname.toLowerCase());
    const domainMatched = hostname.match(domainRegex);
    xtLoginUrl = domainMatched
      ? `https://login${urlEnv}.xtime.app.coxautoinc.com`
      : `https://login${urlEnv}.xtime.com`;

    // ERP case: when RO embedded in iframe, read loginUrl from DB field
    if (hostname.match(/(nextworld.net)/g)) {
      const iframehostname = String(document.location.hostname).toLowerCase();
      const matched = iframehostname.match(domainRegex);
      xtLoginUrl = matched
        ? `https://login${urlEnv}.xtime.app.coxautoinc.com`
        : `https://login${urlEnv}.xtime.com`;
    }
    console.log("build xtLoginUrl", xtLoginUrl);
  } catch (e) {
    xtLoginUrl = appConfig.loginURL || "";
    console.error("buildXTLoginUrl", e);
  }
  return xtLoginUrl;
}

export function getServiceQuoteUiConfig(
  appEnv,
  checkModuleAccess,
  noAccessAction,
  appSource,
  setAppConfigParams,
  appType
) {
  axios
    .get(
      portalUrl +
        "/config/ui?appEnv=" +
        appEnv +
        "&appSource=" +
        appSource +
        "&appType=" +
        appType
    )
    .then(function (result) {
      const appConfig = result.data[appEnv];
      baseAppConfig = appConfig;
      baseLoginUrl = buildXTLoginUrl(appConfig);
      baseNodeJsPrintUrl = isEmptyObject(appConfig.nodejsprintUrl)
        ? ""
        : appConfig.nodejsprintUrl;
      // @note: read {xmmextUrl} from rest API; if field not returned in API then use hosts.config.json field {catalogImageUrl}
      baseImgUrl = get(
        appConfig,
        "xmmextUrl",
        uiConfig[namespace].catalogImageUrl
      );
      console.log("xmmextUrl", baseImgUrl);
      createXmmAxios(appConfig);
      checkModuleAccess();
      setAppConfigParams(appConfig);
    })
    .catch(error => {
      console.error("getServiceQuoteUiConfig", error);
      noAccessAction();
    });
}

export function loadUiConfig(
  checkModuleAccess,
  noAccessAction,
  appEnv,
  appSource,
  setAppConfigParams,
  appType
) {
  initializeUiConfig(uiConfig);
  getServiceQuoteUiConfig(
    appEnv,
    checkModuleAccess,
    noAccessAction,
    appSource,
    setAppConfigParams,
    appType
  );
  // move callbacks to async fun()
}
/* Create instance of axios to support rest endpoint */
function createXmmAxios(appConfig) {
  xmmAxios = axios.create({
    baseURL: portalUrl,
    // TODO: Future use - Pass custom locale value in headers
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
      // TODO: these headers to pass locale in rest API
      // "Accept-Language": getLocaleString(),
      // "catalog-locale": getLocaleString(),
    },
    signal: globalAbortController.signal
  });

  // Global axios defaults
  xmmAxios.defaults.headers.post["Content-Type"] = "application/json";
  // @note: Here, we can set default timeout for all requests eg: 5000 ms = 2.5 seconds
  // xmmAxios.defaults.timeout = 5000;

  // * Add a request interceptor
  xmmAxios.interceptors.request.use(
    request => {
      request.startTime = new Date();
      // IMPORTANT: Update request headers with these values read from browser queryParams
      request.headers["appEnv"] = appConfig.appEnv;
      request.headers["appSource"] = appConfig.appSource;
      // @appType logic now applies to both CSR and SQ
      if (appType) {
        request.headers["appType"] = appType;
      }
      return request;
    },
    error => {
      return Promise.reject(error);
    }
  );

  // * Add a response interceptor- to handle response and error objects
  // ? IMPORTANT - Using this interceptors we can transform API Response to common defined format, only when each rest API return "data: {}"
  xmmAxios.interceptors.response.use(
    function (response) {
      const { status } = response;
      if (status < 300) {
        return response;
      } else {
        clearBodyMask(); // generic case - remove masking for any failure calls
        printErrorCode(response);
        if (![400, 401, 404].includes(status)) {
          eventBus.emit("api-error", {
            status,
            message: xlate("sq.errors.api.unkown_error")
          });
        }
        throw new Error(response);
      }
    },
    error => {
      // response error handling
      if (error?.response && error?.response?.status) {
        const { status } = error.response;
        printErrorCode(error.response);
        if (![400, 401, 404].includes(status)) {
          eventBus.emit("api-error", {
            status,
            message: xlate("sq.errors.api.unkown_error")
          });
        }
        if (status === 401 || status === 404) {
          return error.response;
        }
        console.error(
          `%c Server ERROR: ${error}`,
          "background: #f00; color: #fff"
        );
        clearBodyMask(); // generic case - remove masking for any failure calls
      }
      throw error;
    }
  );
}

// Case: when XT session expired and rest api failed, redirect to landing page as below
// Quoting: https://loginua9.xtime.app.coxautoinc.com/?pt=sq&sqenv=qa6&webKey=xtimequotingxx1&act=dashboard
// CSR: https://loginua9.xtime.app.coxautoinc.com/?pt=csr&webKey=coxautodmsplus2xx1&appEnv=ua9&csrenv=qa6&act=dashboard
// Logout: https://loginua9.xtime.app.coxautoinc.com/?pt=sq&sqenv=qa6&webKey=
export function redirectToDefaultPage(queryStr) {
  const xtLoginUrl = generateSQLoginUrl(queryStr || "");
  console.log("redirectToDefaultPage - XT LoginURL", xtLoginUrl);
  document.location.replace(xtLoginUrl);
}

/**
 * Generates the Service Quoting (SQ) login URL based on the given query string.
 *
 * @param {string} queryStr - The query string used to generate the login URL.
 * @returns {string} The generated SQ login URL.
 */
const generateSQLoginUrl = queryStr => {
  const xtLoginUrl = new URL(baseLoginUrl);
  let ptParam = "sq";
  let webKeyParam = "";
  let routingPage = "";

  const isProdNamespace = namespace === "prod" || namespace === "prodaus";
  const namespaceEnv = isProdNamespace ? "" : namespace;
  const appEnv = isProdNamespace ? "" : baseAppConfig?.appEnv ?? "";

  if (queryStr && queryStr.length > 0) {
    const baseParams = queryStringToJSON();
    webKeyParam = baseParams?.webKey || "";
    routingPage = "dashboard";
  }
  if (appType && appType === isCSR) {
    ptParam = "csr";
    xtLoginUrl.searchParams.append("csrenv", namespaceEnv);
  } else {
    xtLoginUrl.searchParams.append("sqenv", namespaceEnv);
  }
  xtLoginUrl.searchParams.append("pt", ptParam);
  xtLoginUrl.searchParams.append("webKey", webKeyParam);
  xtLoginUrl.searchParams.append("appEnv", appEnv);
  xtLoginUrl.searchParams.append("act", routingPage);
  return xtLoginUrl.toString();
};

// TODO: Read locale value from App level localStorage
export function getLocaleString() {
  let locale = localStorage.getItem("locale");
  if (!locale) {
    locale = DEFAULT_LOCALE;
  }
  return locale;
}
/* set locole in localStorage */
export function setLocale(locale) {
  localStorage.setItem("locale", locale);
}

/* Method return service URL */
function getURLOnly(str) {
  // return str.split("xtime.com/")[1];
  return str.split("?")[0];
}

/* 
@feature - Track rest API response time as GA Event 
*/
function logResponseTime(request, response) {
  let serviceName = "";
  const start = request.startTime;
  const end = new Date().getTime();
  const loadTime = end - start.getTime();
  if (request) {
    serviceName = getURLOnly(request.url);
    const logObj = {
      method: request.method,
      status: response.status,
      serviceName,
      loadTime,
      requestPayload: createRequestPayload(request)
    };
    gtag.logRestAPI(logObj, response);
  }
}
// method used as util to prepare payload from API request object for GA
function createRequestPayload(request) {
  let payload = "";
  if (request.method === "get") {
    payload = !request.params ? "" : JSON.parse(JSON.stringify(request.params));
  } else if (request.method === "post") {
    payload = !request.data ? "" : JSON.parse(JSON.stringify(request.data));
  }
  return payload;
}

/* Util to capture Http Errors in GA */
function printErrorCode(response) {
  const error = httpCodes[response.status];
  const logObj = {
    eventName: "APIError",
    method: response.config.method,
    statusCode: response.status,
    message: !error ? "UNKNOWN Error" : error,
    serviceName: getURLOnly(response.config.url)
  };
  gtmEvent.trackGAError(logObj);
}
export function jwtXsession(callback, errorHandler) {
  let accessToken = localStorage.getItem("accessToken");
  let refreshToken = localStorage.getItem("refreshToken");
  // remove check for dev6 url
  if (hostname.indexOf("localhost") === -1) {
    if (accessToken == null || refreshToken == null) {
      // detect pending xsession calls
      const pendingXsessionCalls = localStorage.getItem("pendingXsessionCalls");
      if (!pendingXsessionCalls || pendingXsessionCalls.length === 0) {
        localStorage.setItem("pendingXsessionCalls", []);
      } else {
        // push pending xsession calls to the queue
        pendingXsessionCalls.push({ callback, errorHandler });
        return;
      }
      // need to get accessToken
      console.log(
        "accessToken/refreshToken are null",
        accessToken,
        refreshToken
      );
      axios
        .post(`${baseLoginUrl}/rest/login/jwt/xsession`, null, {
          withCredentials: true
        })
        .then(response => {
          console.log("jwtSession.response", response);
          if (response.status === 200 && response.data && response.data.data) {
            accessToken = response.data.data.accessToken;
            refreshToken = response.data.data.refreshToken;
            const timeOffsetInSecs = localStorage.getItem("timeOffsetInSecs");
            if (!timeOffsetInSecs) {
              const decodedAccessToken = jwtDecode(accessToken);
              const currentTimeInSecs = Math.floor(new Date().getTime() / 1000);
              const timeDiffInSecs = decodedAccessToken.iat - currentTimeInSecs;
              localStorage.setItem("timeOffsetInSecs", timeDiffInSecs);
              console.log("timeOffsetInSecs", timeDiffInSecs);
            }
            localStorage.setItem("accessToken", accessToken);
            localStorage.setItem("refreshToken", refreshToken);
            callback(response.data.data);
            const pendingXsessionCalls = localStorage.getItem(
              "pendingXsessionCalls"
            );
            if (pendingXsessionCalls) {
              console.log("pendingXsessionCalls", pendingXsessionCalls);
              pendingXsessionCalls.forEach(apiCall => {
                // call
                apiCall.callback(response.data.data);
              });
              localStorage.removeItem("pendingXsessionCalls");
            }
          } else {
            handleSessionExpiration(response.data);
          }
        })
        .catch(error => {
          // handle errors in common place
          if (errorHandler) {
            const message =
              !error || error.message === NETWORK_ERROR
                ? xlate("sq.errors.network.error")
                : error.message;
            errorHandler({
              success: false,
              message
            });
          }
        });
    } else {
      // accessToken and refreshToken exist
      // const decoded = jwtDecode(accessToken);
      callback({ accessToken });
    }
  } else {
    // hack for localhost
    callback();
  }
}

export function jwtRefresh(callback, errorHandler) {
  if (hostname.indexOf("localhost") !== -1) {
    if (callback) {
      callback();
    }
    return;
  }
  let accessToken = localStorage.getItem("accessToken");
  let refreshToken = localStorage.getItem("refreshToken");

  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json"
  };
  // IMPORTANT: ** DO NOT SEND any custom http header, it would cause jwt/refresh to choke
  if (refreshToken !== null) {
    headers.Authorization = `Bearer ${refreshToken}`;
  }
  axios({
    url: `${baseLoginUrl}/rest/login/jwt/refresh`,
    method: "get",
    data: {},
    params: {},
    headers
  })
    .then(response => {
      if (
        (response.status === 200 || response.status === 201) &&
        response.data
      ) {
        accessToken = response.data.accessToken;
        refreshToken = response.data.refreshToken;
        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem("refreshToken", refreshToken);
        if (callback) {
          callback();
        }
      }
    })
    .catch(() => {
      // failed to get refresh token, let's call /jwt/xsession
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("pendingRefreshCalls");
      jwtXsession(callback, errorHandler);
    });
}

function handleSessionExpiration(eventObject) {
  window.dispatchEvent(
    new CustomEvent("sessioinExpired", {
      detail: { eventObject },
      bubbles: true,
      cancelable: true
    })
  );
}

function getResponseURL(response) {
  if (response && response.request && response.request.responseURL) {
    return response.request.responseURL;
  }
  return "";
}

/**
 * Executes an API call.
 * @param {object} params Request configuration. Mostly standard axios properties, plus a few custom ones.
 * @param {string} params.url Partial URL (everything after "/rest"). Required.
 * @param {string} params.method HTTP request method. Required.
 * @param {object?} params.headers KVP object with custom headers to send, if any.
 * @param {string?} params.dataType Request data type. Only needed if it's not JSON.
 * @param {object?} params.data Request payload. Only needed for PUT/POST/PATCH calls.
 * @param {object?} params.params KVP object with request query params, if any.
 * @param {string?} params.responseType Expected response type. Only needed if it's not JSON.
 * @param {number?} params.refreshCount How many times the JWT has been refreshed. Only used internally. Should not be set by calling code.
 * @param {function} respondHandler Function to handle successful response.
 * @param {function} errorHandler Function to handle error response.
 * @param {string?} failureMessage Message to show user if the API call fails. If not provided, it will fail quietly.
 */
export function makeSecureRestApi(
  params,
  respondHandler,
  errorHandler,
  failureMessage
) {
  if (hostname.indexOf("localhost") === -1) {
    const accessToken = localStorage.getItem("accessToken");
    const refreshToken = localStorage.getItem("refreshToken");
    if (accessToken && refreshToken) {
      const decodedAccessToken = jwtDecode(accessToken);
      const currentTimeInSecs = Math.floor(
        (new Date().getTime() + 5000) / 1000
      );
      const timeOffsetInSecs = parseInt(
        localStorage.getItem("timeOffsetInSecs"),
        10
      );
      // console.log(
      //   "makeSecureRestApi",
      //   decodedAccessToken.exp,
      //   currentTimeInSecs,
      //   timeOffsetInSecs
      // );
      if (currentTimeInSecs + timeOffsetInSecs > decodedAccessToken.exp) {
        jwtRefresh(() => {
          makeRestApi(params, respondHandler, errorHandler, failureMessage);
        }, errorHandler);
        return;
      }
    }
  }
  jwtXsession(() => {
    makeRestApi(params, respondHandler, errorHandler, failureMessage);
  }, errorHandler);
}

function incrementRefreshCount(params) {
  if (!params.refreshCount) {
    params.refreshCount = 1;
  }
  params.refreshCount++;
}

/**
 * Shows an API failure notification.
 * Any instance of "CSR" in the message text will be dynamically replaced
 * with "Quote" or "RO" as applicable.
 * @param {string} failureMessage Message to display.
 * @param {object} [error] Axios error object.
 */
export function showApiFailureMessage(failureMessage, error) {
  if (failureMessage && !(error && axios.isCancel(error))) {
    toast.error(
      failureMessage.replace(/CSR/g, appType === isCSR ? "RO" : "Quote")
    );
  }
}

function makeRestApi(params, respondHandler, errorHandler, failureMessage) {
  // Http Header
  const accessToken = localStorage.getItem("accessToken");
  const headers = params.headers
    ? params.headers
    : {
        Accept: "application/json",
        "Content-Type": "application/json"
      };
  if (accessToken !== null) {
    headers.Authorization = `Bearer ${accessToken}`;
  }
  if (params.dataType === "csv") {
    headers["Content-Type"] = "text/csv";
    headers["Accept"] = "text/csv";
  }
  xmmAxios({
    url: params.url,
    method: params.method,
    data: params.data,
    params: params.params,
    headers,
    responseType: params.responseType
  })
    .then(response => {
      if (
        response.status >= 300 &&
        ![400, 401, 404].includes(response.status)
      ) {
        eventBus.emit("api-error", {
          status: response?.status,
          message: failureMessage
        });
      }
      // http success status code
      if (response.status < 300) {
        respondHandler(response.data, response.headers);
      } else if ([400, 404].includes(response.status)) {
        errorHandler(response.data);
      } else if (response.status === 401) {
        const responseURL = response ? getResponseURL(response) : "";
        if (responseURL.indexOf(dealerApiUrl) !== -1) {
          eventBus.emit("api-error", {
            status: response?.status,
            message: xlate("sq.common.unauthorized_dealer")
          });
        } else if (responseURL.indexOf(userApiUrl) !== -1) {
          xlate("sq.common.unauthorized_user");
          eventBus.emit("api-error", {
            status: response?.status,
            message: xlate("sq.common.unauthorized_user")
          });
        } else {
          // refresh jwt token
          jwtRefresh(() => {
            incrementRefreshCount(params);
            if (params.refreshCount > MAX_REFRESH_CALLS) {
              eventBus.emit("api-error", {
                status: response?.status,
                message: xlate("sq.common.unauthorized_user")
              });
              return response.status;
            }
            makeRestApi(params, respondHandler, errorHandler);
          }, errorHandler);
        }
      }
      return response.status;
    })
    .catch(error => {
      // push api errors in toast container
      showApiFailureMessage(failureMessage, error);
      if (errorHandler) {
        if (error) {
          error.message = error?.message || xlate("sq.errors.network.error");
        }
        errorHandler(error);
      }

      return Promise.reject(error);
    });
}

export function getMakeBrandLogoUrl(make, variant) {
  const brandLogoUrl =
    make && variant
      ? `${baseImgUrl}/oe/xmm/getBrandLogo?make=${make}&variant=${variant}&locale=US_EN&key=DEFAULT_LOGO`
      : "";
  return brandLogoUrl;
}

export function getVehicleImage(make, model, year, countryCode) {
  const imageUrl = `${baseImgUrl}/oe/getVehicleImage?make=${encodeURI(
    make
  )}&model=${encodeURI(model)}&year=${year}&countryCode=${countryCode}`;
  return imageUrl;
}
// Local Util to remove masking effect on page when API failed
function clearBodyMask() {
  const rootElement = document.getElementById("bodymask");
  rootElement.className = rootElement.className.replace(
    /\bxmm-loading-mask\b/g,
    ""
  );
}
/**
 * returns loginUrl based on environment
 * @returns {string}
 */
export function getLoginUrl() {
  return baseLoginUrl;
}

/**
 * return nodejsprinturl based on env
 * @returns {string}
 */
export function getNodeJsPrintUrl() {
  return baseNodeJsPrintUrl;
}

// This method called for DealerTire integration page
const getQuotingApiUrl = () => {
  const apiUrl =
    appType === isCSR
      ? uiConfig[namespace].csrApi
      : uiConfig[namespace].serviceQuotingApi;

  return apiUrl;
};
// This method called in many wrapper to forward axiosInstance to its children components
const getInstance = () => {
  return xmmAxios;
};
// This method returns derived namespace like {dev6,qa6,stg4,prod,prodaus}
const getQuotingNameSpace = () => {
  return namespace;
};

/**
 * @description Abort Signal Factory
 * @function
 * @param {number} [timeoutMs] Timeout value
 * @returns {AbortController}
 */
function newAbortController(timeoutMs) {
  const abortController = new AbortController();

  if (isNumber(timeoutMs)) {
    setTimeout(() => abortController.abort(), timeoutMs);
  }

  return abortController;
}

// This method called to update request headers with these values read from browser queryParams
const setupAxios = config => {
  const { appSource, appEnv, webKey, dealer, appType } = config;
  const axiosInstance = getInstance();

  axiosInstance.interceptors.request.use(
    config => {
      config.url = config.url.replace("{webKey}", webKey);
      config.url = config.url.replace("{dealerCode}", dealer.dealerCode);
      const token = `Bearer ${localStorage.getItem("accessToken") || ""}`;
      config.headers.Authorization = token;
      config.headers.appSource = appSource;
      config.headers.appEnv = appEnv;
      // @ TBD- check appType
      config.headers.appType = appType;
      return config;
    },
    error => {
      return Promise.reject(error);
    }
  );
  return axiosInstance;
};

export function getBridgeV2Configs(env, callback) {
  function send() {
    const xmlhttp = new XMLHttpRequest();

    const matches = window.location.host.match(/[^.]+\.[^.]+\.[^.]+\.[^.]+$/);
    const host = matches ? matches[0] : window.location.host;
    const url = "https://login" + env + "." + host + "/rest/login/bridgebar";
    // const url = "https://loginua9.xtime.app.coxautoinc.com/rest/login/bridgebar";

    xmlhttp.onreadystatechange = function () {
      if (this.readyState == 4) {
        if (this.status == 200) {
          const data = JSON.parse(this.responseText).data;
          getBridgeUserInfo(env, data.sessionId, callback);
        }
      }
    };
    xmlhttp.withCredentials = true;
    xmlhttp.open("GET", url, true);
    xmlhttp.setRequestHeader("Accept", "application/json");
    xmlhttp.setRequestHeader("Content-Type", "application/json");
    xmlhttp.send();
  }

  send();
}

export function getBridgeUserInfo(env, bridgeSessionId, callback) {
  function send() {
    const xmlhttp = new XMLHttpRequest();
    const solutionId = env === "" ? "xti" : "xti_qa";
    const bridgeEnv = env === "" ? "prod" : "qa";
    const partition = env === "" ? "" : "&partition=NON_PROD";

    const host = window.location.host.replace(/^[^.]+\./, "");
    const url =
      "https://signin.coxautoinc.com/bridgebar/user-details/mine?solutionId=" +
      solutionId +
      "&bridgeEnv=" +
      bridgeEnv +
      "&sessionId=" +
      bridgeSessionId +
      partition +
      "&language=en";

    xmlhttp.onreadystatechange = function () {
      if (this.readyState == 4) {
        if (this.status == 200) {
          const data = JSON.parse(this.responseText);
          localStorage.setItem("coxBridgeUserId", data.id);
          callback();
        }
      }
    };
    xmlhttp.withCredentials = true;
    xmlhttp.open("GET", url, true);
    xmlhttp.setRequestHeader("Accept", "application/vnd.coxauto.v1+json");
    xmlhttp.setRequestHeader("Content-Type", "application/json");
    xmlhttp.send();
  }

  send();
}

export default {
  globalAbortController,
  xmmAxios,
  getQuotingApiUrl,
  setupAxios,
  getQuotingNameSpace,
  newAbortController,
  logResponseTime,
  getInstance
};

/* eslint-enable no-console */
